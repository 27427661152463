/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/state-in-constructor */
import { Component, ErrorInfo, ReactNode } from 'react';

import type { Nullable } from '@teampetfriends/util-types';

interface State<ErrorState extends Error = Error> {
  error: Nullable<ErrorState>;
}

type RenderFallbackProps = {
  reset: () => void;
  error: Error;
};

interface ErrorBoundaryProps {
  children: ReactNode;
  renderFallback: (fallbackProps: RenderFallbackProps) => ReactNode;
  onError?: (error: Error, stack: ErrorInfo) => void;
  onReset?: () => void;
}

const initialState: State = { error: null };

class DefaultErrorBoundary extends Component<ErrorBoundaryProps, State> {
  state: State = initialState;

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const { onError } = this.props;

    onError?.(error, errorInfo);
  }

  resetErrorBoundary = () => {
    const { onReset } = this.props;

    onReset?.();

    this.setState(initialState);
  };

  render() {
    const { children, renderFallback } = this.props;
    const { error } = this.state;

    if (error !== null) {
      return renderFallback({ error, reset: this.resetErrorBoundary });
    }

    return children;
  }
}

function ErrorBoundary(props: ErrorBoundaryProps) {
  return <DefaultErrorBoundary {...props} />;
}

export default ErrorBoundary;
