import { CLAIM_INQUIRE_INITIAL_STATE } from '@claim/ClaimInquire/module/constants';
import { COUPON_REQUEST_INITIAL_STATE } from '@coupon/CouponRequest/modules/constant';
import { INBOUND_REQUEST_INITIAL_STATE } from '@inbound/InboundRequest/modules/constant';
import { PRODUCT_MODIFY_INITIAL_STATE } from '@product/ProductModifyRequest/constant';

export const ALARM_TABS = [
  { key: '', value: '전체' },
  { key: 'PRODUCT', value: '상품' },
  { key: 'DELIVERY', value: '배송' },
  { key: 'CLAIM', value: '교환/반품' },
  { key: 'COUPON', value: '쿠폰' },
  { key: 'SETTLEMENT', value: '정산' },
  { key: 'INBOUND', value: '입고' },
];

export const ALARM_ROUTE_TABLE_WITH_PARAMETERS = {
  PRODUCT: {
    path: '/vendor/product/modify',
    params: PRODUCT_MODIFY_INITIAL_STATE,
  },
  CLAIM: {
    path: '/exchange-return',
    params: CLAIM_INQUIRE_INITIAL_STATE,
  },
  COUPON: {
    path: '/coupon/request',
    params: COUPON_REQUEST_INITIAL_STATE,
  },
  INBOUND: {
    path: '/inbound/request',
    params: INBOUND_REQUEST_INITIAL_STATE,
  },
};
