import { lazy, Suspense, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { jwtDecode } from '@teampetfriends/utils';

import { useAtom } from 'jotai';

import FindPassword from '@auth/FindPassword';
import Login from '@auth/Login';
import Dashboard from '@dashboard/Dashboard';
import { useAuthInfo } from '@hooks/useSyncAuth';
import DefaultLayout from '@layouts/DefaultLayout';
import MainRedirect from '@layouts/MainRedirect';
import { visibleAtom } from '@store/atoms';
import { TokenInfo } from '@utils/token';

const ProductInquire = lazy(() => import('@product/ProductInquire'));
const ProductRequest = lazy(() => import('@product/ProductRequest'));
const DeliveryManage = lazy(() => import('@delivery/DeliveryManage'));
const ProductModifyRequest = lazy(() => import('@product/ProductModifyRequest'));
const OutboundInquire = lazy(() => import('@outbound/OutboundInquire'));
const StocksInquire = lazy(() => import('@stocks/StocksInquire'));
const ItemsInquire = lazy(() => import('@items/ItemsInquire'));
const ItemsUpload = lazy(() => import('@items/ItemsUpload'));
const InboundRequest = lazy(() => import('@inbound/InboundRequest'));
const VendorSettlementInquire = lazy(() => import('@settlement/VendorSettlementInquire'));
const ConsignmentSettlementInquire = lazy(() => import('@settlement/ConsignmentSettlementInquire'));
const ProductWriteV3 = lazy(() => import('@product/ProductWriteV3'));
const ProductPackaging = lazy(() => import('@product/package'));
const ClaimInquire = lazy(() => import('@claim/ClaimInquire'));
const ClaimDetail = lazy(() => import('@claim/ClaimDetail'));
const CouponRequest = lazy(() => import('@coupon/CouponRequest'));
const CouponSettlement = lazy(() => import('./pages/settlements/CouponSettlement'));
const CouponReport = lazy(() => import('@coupon/CouponReport'));
const CouponIssuance = lazy(() => import('@coupon/CouponIssuance/CouponIssuance'));
const NoticeInquire = lazy(() => import('@notice/NoticeInquire'));
const NoticeDetail = lazy(() => import('@notice/NoticeDetail'));
const NoticeWrite = lazy(() => import('@notice/NoticeWrite'));

function PrivateRoute() {
  const {
    cookie: [userToken],
  } = useAuthInfo(['accessToken']);

  const user = userToken ? jwtDecode<TokenInfo>(userToken) : null;

  if (!userToken) return <Navigate to='/login' replace />;

  if (user?.is_vendor && !user?.password_reset) return <Navigate to='/login/password' replace />;

  return (
    <DefaultLayout>
      <Suspense fallback={<div />}>
        <Outlet />
      </Suspense>
    </DefaultLayout>
  );
}

function RouteElement() {
  const [visible, setVisible] = useAtom(visibleAtom);

  useAuthInfo(['accessToken']);

  useEffect(() => {
    // const isVisible = import.meta.env.VITE_ENV === 'production' ? 'invisible' : 'visible';
    // setVisible(isVisible);
    setVisible('invisible');
  }, []);

  // NOTE: 화면에 노출되지 말아야할 화면리스트
  // const deRenderRouteElement = useCallback(() => {
  //   if (visible === 'invisible') return null;

  //   return null;
  // }, [visible]);

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        {/* <Route path='/' element={<MainRedirect />} /> */}
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/delivery/manage' element={<DeliveryManage />} />
        {/* 상품등록 */}
        <Route path='/product/form' element={<ProductWriteV3 />} />
        {/* 상품변경요청 */}
        <Route path='/vendor/product/modify' element={<ProductModifyRequest />} />
        {/* 상품변경요청 > 상품등록 화면 */}
        <Route path='/vendor/product/modify/:id' element={<ProductWriteV3 />} />
        {/* 상품변경요청 > 상품변경 화면 */}
        <Route path='/vendor/product/modify/package/:id' element={<ProductPackaging />} />

        <Route path='/product/list' element={<ProductInquire />} />
        <Route path='/admin/request/list' element={<ProductRequest />} />

        <Route path='/product/package' element={<ProductPackaging />} />
        <Route path='/product/package/:id' element={<ProductPackaging />} />

        <Route path='/outbound/list' element={<OutboundInquire />} />
        <Route path='/stocks/list' element={<StocksInquire />} />

        <Route path='/items/list' element={<ItemsInquire />} />
        <Route path='/items/list/upload' element={<ItemsUpload />} />

        <Route path='/inbound/request' element={<InboundRequest />} />
        <Route path='/consignment-settlement/list' element={<ConsignmentSettlementInquire />} />
        <Route path='/vendor-settlement/list' element={<VendorSettlementInquire />} />
        <Route path='/exchange-return' element={<ClaimInquire />} />
        <Route path='/exchange-return/:id' element={<ClaimDetail />} />

        <Route path='/notice/list' element={<NoticeInquire />} />
        <Route path='/notice/list/:id' element={<NoticeDetail />} />
        <Route path='/notice/form' element={<NoticeWrite />} />
        <Route path='/notice/form/:id' element={<NoticeWrite />} />

        {/* {deRenderRouteElement()} */}

        <Route path='/coupon/request' element={<CouponRequest />} />
        <Route path='/coupon/request/issuance' element={<CouponIssuance />} />
        <Route path='/coupon/request/issuance/:id' element={<CouponIssuance />} />
        <Route path='/coupon/report' element={<CouponReport />} />
        <Route path='/coupon-settlement/list' element={<CouponSettlement />} />
      </Route>
      <Route path='/login' element={<Login />} />
      <Route path='/login/password' element={<FindPassword />} />
      <Route path='*' element={<MainRedirect />} />
    </Routes>
  );
}

export default RouteElement;
