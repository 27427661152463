import { ButtonHTMLAttributes, ReactNode } from 'react';

import { Box, CSS } from '@teampetfriends/react-ui';

import { useDebounce } from '@hooks/useDebounce';
import { Callback } from '@hooks/useDebounce/type';

const VARIANTS = {
  color: {
    red: 'text-white bg-red-50',
    blue: 'text-white bg-blue-50',
    green: 'text-white bg-green-50',
    black: 'text-white bg-black-100',
    white: 'bg-white border border-grey-500',
  },
  size: {
    small: 'text-[1.2rem] h-[3.4rem]',
    medium: 'text-[1.4rem] h-[4rem]',
    large: 'text-[1.6rem] h-[6rem]',
  },
} as const;

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  onClick?: Callback;
  color?: keyof typeof VARIANTS.color;
  size?: keyof typeof VARIANTS.size;
  width?: string;
  disabled?: boolean;
  className?: string;
  css?: CSS;
}

function Button({
  onClick,
  children,
  width,
  className,
  disabled = false,
  size = 'medium',
  color = 'white',
  type = 'button',
  css = {},
}: ButtonProps) {
  const colorClass = !disabled
    ? VARIANTS.color[color]
    : `border border-solid divide-[#DDD] bg-[#F1F1F1] text-[#C6C9CE]`;

  const onClickWithDebounce = onClick ? useDebounce(onClick, 300) : undefined;

  return (
    <Box
      css={{
        width,
        minWidth: '6rem',
        ...css,
      }}
    >
      <button
        className={`${colorClass} ${VARIANTS.size[size]} px-[1.2rem] rounded-md w-full gap-[0.3rem] whitespace-nowrap text-ellipsis overflow-hidden ${className}`}
        onClick={onClickWithDebounce}
        type={type}
        disabled={disabled}
      >
        {children}
      </button>
    </Box>
  );
}

export default Button;
