import { Flex } from '@teampetfriends/react-ui';

import { SellingPriceTooltip } from '../Tooltips';

export function SellingPriceHeader() {
  return (
    <Flex align='center' justify='center' css={{ columnGap: '.6rem' }}>
      판매금액
      <SellingPriceTooltip />
    </Flex>
  );
}
