import { Flex, Typo } from '@teampetfriends/react-ui';

function NoticeCouponContents() {
  return (
    <>
      <Flex
        css={{ borderBottom: '.1rem solid rgba(0, 0, 0, 0.19)', height: '4.8rem' }}
        align='center'
      >
        <Typo as='h3' css={{ color: '#FE0505', fontWeight: '700', fontSize: '1.8rem' }}>
          NEW
        </Typo>
        &nbsp;
        <Typo as='h3' css={{ fontWeight: '700', fontSize: '1.8rem' }}>
          쿠폰 발행 서비스 오픈
        </Typo>
      </Flex>
      <Typo css={{ fontSize: '1.4rem', lineHeight: '2.4rem', mt: '2.4rem' }}>
        쿠폰 발행 서비스가 출시되어 안내드립니다.
        <br />
        파트너는 쿠폰메뉴에서 상품쿠폰을 직접 발행 및 관리할 수 있습니다.
      </Typo>

      <Flex direction='column' css={{ my: '2.4rem' }}>
        <Typo css={{ fontSize: '1.6rem', fontWeight: 700, mb: '.6rem' }}>추가 기능</Typo>
        <Flex as='ul' direction='column'>
          <Typo as='li' css={{ listStyle: 'inside', fontSize: '1.4rem', lineHeight: '2.4rem' }}>
            상품쿠폰 발행 및 관리
          </Typo>
          <Typo as='li' css={{ listStyle: 'inside', fontSize: '1.4rem', lineHeight: '2.4rem' }}>
            쿠폰 리포트 조회
          </Typo>
          <Typo as='li' css={{ listStyle: 'inside', fontSize: '1.4rem', lineHeight: '2.4rem' }}>
            쿠폰 정산내역 조회
          </Typo>
        </Flex>
      </Flex>

      <Typo css={{ fontSize: '1.4rem', lineHeight: '2.4rem', my: '2.4rem' }}>
        서비스 이용방법은 쿠폰 매뉴얼을 확인 부탁드립니다.
        <br /> 감사합니다.
      </Typo>

      <Flex
        as='a'
        download='판매자어드민_파트너쿠폰_매뉴얼.pdf'
        href='/partner_coupon.pdf'
        align='center'
        justify='center'
        css={{
          margin: '0 auto',
          width: '27rem',
          height: '4.6rem',
          border: '.1rem solid rgba(0, 0, 0, 0.70)',
          borderRadius: '.7rem',
          color: 'rgba(0, 0, 0, 0.70)',
          fontWeight: 600,
        }}
      >
        쿠폰 매뉴얼 다운로드
      </Flex>
    </>
  );
}

export default NoticeCouponContents;
