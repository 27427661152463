import { ReactNode } from 'react';

import { NotificationProvider } from '../context/createNotificationContext';
import type { NotificationContext } from '../interfaces';

interface NotificationWrapperProps extends NotificationContext {
  children: ReactNode;
}

function NotificationWrapper({ children, ...contexts }: NotificationWrapperProps) {
  return <NotificationProvider value={contexts}>{children}</NotificationProvider>;
}

export default NotificationWrapper;
