import { ChangeEvent, ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';

import { CSS } from '@teampetfriends/react-ui';

import { ErrorSpan, WithLabel } from '@components/Label';
import { removeEmoticon } from '@utils/util';

const VARIANTS = {
  borderColor: {
    black: 'border-black',
    blue: 'border-blue-600',
    grey: 'border-grey-300',
    green: 'border-green-500',
    red: 'border-red-500',
    white: 'border-white',
  },
} as const;

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  borderColor?: keyof typeof VARIANTS.borderColor;
  width?: string;
  error?: string | boolean;
  errorStyle?: CSS;
  isGuide?: boolean;
}

export const Input = forwardRef(
  (
    {
      borderColor = 'grey',
      width = 'w-full',
      className,
      error,
      errorStyle,
      isGuide,
      onChange,
      ...rest
    }: InputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const classNames = `${width} ${
      !error || isGuide ? VARIANTS.borderColor[borderColor] : 'border-red-100 bg-pink-30'
    } outline-0 border border-1 text-black-400 rounded-[.5rem] focus:border-gray-300 disabled:bg-gray-200 disabled:border-gray-300 py-0 px-[1.4rem] h-[4rem] ${className}`;

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      e.target.value = removeEmoticon(e.target.value);

      onChange?.(e);
    };

    return (
      <>
        <input ref={ref} className={classNames} onChange={onInputChange} {...rest} />
        {typeof error !== 'boolean' && <ErrorSpan error={error} css={errorStyle} />}
      </>
    );
  }
);

export function InputLabel({ label, id, ...rest }: InputProps & { label: string }) {
  return (
    <WithLabel label={label} id={id}>
      <Input id={id} {...rest} />
    </WithLabel>
  );
}
