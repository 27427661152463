import { Box, Flex, styled } from '@teampetfriends/react-ui';

import { Autoplay } from 'swiper/modules';
import { Swiper as SwiperContainer, SwiperSlide } from 'swiper/react';

import { TopAlarmSpeaker } from '@icons/svg';

import { useGetTopNoticeList } from './modules/apis';
import TopNotificationItem from './modules/components/TopNotificationItem';

const SwiperContainerWithStyled = styled(SwiperContainer);

function TopNotification() {
  const { noticeTopList } = useGetTopNoticeList();

  if (!noticeTopList.length) return null;

  return (
    <Flex align='center' css={{ columnGap: '1rem', mt: '-.2rem' }}>
      <Flex align='center' justify='center' css={{ width: '2rem', height: '2rem' }}>
        <TopAlarmSpeaker />
      </Flex>
      <Box css={{ height: '2.2rem', overflow: 'hidden' }}>
        <SwiperContainerWithStyled
          direction='vertical'
          modules={[Autoplay]}
          loop
          noSwiping
          initialSlide={0}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          css={{ height: '100%' }}
        >
          {noticeTopList.map((item) => (
            <SwiperSlide key={item.noticeId}>
              <TopNotificationItem {...item} />
            </SwiperSlide>
          ))}
        </SwiperContainerWithStyled>
      </Box>
    </Flex>
  );
}

export default TopNotification;
