import { getCalcDate } from '@utils/util';

import type { GetProductModifyListParams } from './apis/type';

export const PRODUCT_MODIFY_REQUEST_CHECKBOX_LIST = [
  { id: 'REGISTER', labelText: '상품등록' },
  { id: 'MODIFY', labelText: '상품정보 변경' },
  { id: 'PRICE', labelText: '판매가격 변경' },
  { id: 'STATUS', labelText: '판매상태 변경' },
  { id: 'CONNECTION', labelText: '상품 연결' },
] as const;
export type ProductModifyRequestCheckboxItem =
  (typeof PRODUCT_MODIFY_REQUEST_CHECKBOX_LIST)[number]['id'];

export const PRODUCT_MODIFY_STATUS_CHECKBOX_LIST = [
  { id: 'SAVED', labelText: '임시저장' },
  { id: 'REQUESTED', labelText: '승인 대기' },
  { id: 'CONFIRMED', labelText: '승인 완료' },
  { id: 'REJECTED', labelText: '승인 반려' },
  { id: 'CANCELED', labelText: '요청 취소' },
] as const;

export type ProductModifyStatusCheckboxItem =
  (typeof PRODUCT_MODIFY_STATUS_CHECKBOX_LIST)[number]['id'];

export const PRODUCT_MODIFY_INITIAL_STATE: GetProductModifyListParams = {
  startDate: getCalcDate(new Date(), 1, 'month'),
  endDate: new Date(),
  productStockType: '',
  searchCondition: 'PRODUCT_NAME',
  searchContents: '',
  actions: null,
  statuses: null,
  pageNumber: 1,
  pageSize: 10,
};
