import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const TEST_MOCK = 'mock_key';

const firebaseConfig =
  import.meta.env.NODE_ENV !== 'test'
    ? {
        apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
        authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
        databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
        projectId: import.meta.env.VITE_PROJECT_ID,
        storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
        messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
      }
    : {
        apiKey: TEST_MOCK,
        authDomain: TEST_MOCK,
        databaseURL: TEST_MOCK,
        projectId: TEST_MOCK,
        storageBucket: TEST_MOCK,
        messagingSenderId: TEST_MOCK,
      };

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
