import ErrorBoundary from '@components/ErrorBoundary';
import Loading from '@components/Loading';
import Modal from '@components/Modal';

import Route from './route';

function App() {
  return (
    <section>
      <Route />
      <Modal />
      <Loading />
    </section>
  );
}

export default App;
