import { useMemo } from 'react';

import { CSS, Typo } from '@teampetfriends/react-ui';

const ALARM_STYLES: CSS[] = [
  {
    width: '2rem',
    height: '2rem',
    borderRadius: '50%',
  },
  {
    borderRadius: '2rem',
    padding: '.2rem .5rem',
  },
  {
    borderRadius: '2rem',
    padding: '.2rem .6rem',
  },
];

export function BoardBadge({ count = 0 }: { count: number }) {
  const alarmStyles = useMemo(() => {
    const countLength = count > 99 ? 3 : String(count).length;

    return ALARM_STYLES[countLength - 1] ?? ALARM_STYLES[0];
  }, [count]);

  const alarmCount = useMemo(() => {
    if (count > 99) return '99+';

    return count;
  }, [count]);

  return (
    <Typo
      css={{
        background: '#FF4081',
        textAlign: 'center',
        lineHeight: '2rem',
        fontWeight: 700,
        fontSize: '1.4rem',
        color: '#fff',
        ...alarmStyles,
      }}
    >
      {alarmCount}
    </Typo>
  );
}
