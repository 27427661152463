import dayjs from 'dayjs';

import type { ClaimsParams } from '@claim/ClaimInquire/module/interfaces';

export const CLAIM_INQUIRE_INITIAL_STATE: ClaimsParams = {
  dateCondition: 'CLAIM_DATE',
  startDate: dayjs().subtract(1, 'M').toDate(),
  endDate: dayjs().toDate(),
  productStockType: '',
  claimStatus: '',
  claimType: '',
  searchContents: '',
  searchCondition: 'CLAIM_ID',
  pageNumber: 1,
  pageSize: 10,
  triggerLocation: 'FILTER',
};

export const CLAIM_INITIAL_PARAMS_VERSION_TAB: ClaimsParams = {
  dateCondition: 'CLAIM_DATE',
  startDate: dayjs().subtract(1, 'M').toDate(),
  endDate: dayjs().toDate(),
  productStockType: 'VENDOR',
  claimStatus: '',
  claimType: '',
  searchCondition: 'CLAIM_ID',
  searchContents: '',
  pageNumber: 1,
  pageSize: 10,
  triggerLocation: 'TAB',
};

export const CLAIM_STATUS_LIST = [
  { id: 'REQUESTED', label: '접수', subLabel: '빨리 처리해주세요!', summaryKey: 'requestedCount' },
  {
    id: 'APPROVED',
    label: '승인',
    subLabel: '수거송장을 등록해주세요.',
    summaryKey: 'approvedCount',
  },
  { id: 'COLLECTION_PROGRESS', label: '수거중', summaryKey: 'collectionProgressCount' },
  {
    id: 'COLLECTION_COMPLETED',
    label: '수거완료',
    subLabel: '환불완료/교환재배송 처리해주세요.',
    summaryKey: 'collectionCompletedCount',
  },
  { id: 'DELIVERY_PROGRESS', label: '재배송중', summaryKey: 'deliveryProgressCount' },
];
