import { Link } from 'react-router-dom';

import { Flex, styled, Typo } from '@teampetfriends/react-ui';

import Badge from '@commons/components/Badge';
import { BREAK_POINT } from '@dashboard/Dashboard';
import NewBadge from '@notice/NoticeInquire/modules/components/NoticeResult/modules/components/NoticeItem/modules/components/NewBadge';
import { NOTICE_INQUIRE_INITIAL_STATE } from '@notice/NoticeInquire/modules/constant';
import { type NoticeItem as NoticeItemProps } from '@notice/NoticeInquire/modules/interfaces';
import { convertObjectToQueryString } from '@utils/queryString';

const StyledNoticeItemLink = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  columnGap: '1rem',
  '&:link': {
    '& h4': {
      color: '#1C1B1B',
    },
    '& .notice-info': {
      color: '#9CA1AA',
    },
  },
  '&:visited': {
    '& h4': {
      color: '#C6C9CE',
    },

    '& .notice-info': {
      color: '#C6C9CE',
    },
  },
});

function NoticeItem({
  title,
  pinned,
  noticeCategory,
  isNew,
  noticeId,
  createdDate,
}: NoticeItemProps) {
  const defaultQueryString = () => convertObjectToQueryString(NOTICE_INQUIRE_INITIAL_STATE);

  return (
    <li>
      <StyledNoticeItemLink
        to={{ pathname: `/notice/list/${noticeId}`, search: defaultQueryString() }}
      >
        <Flex align='center' css={{ columnGap: '1rem', width: 'calc(100% - 11rem)' }}>
          {pinned && (
            <Badge color='red' size='s'>
              고정
            </Badge>
          )}
          {noticeCategory && (
            <Badge color='grey' size='s'>
              {noticeCategory}
            </Badge>
          )}

          <Flex align='center' css={{ columnGap: '.4rem' }}>
            <Typo
              as='h4'
              css={{
                fontSize: '1.4rem',
                fontWeight: 500,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '26rem',
                width: 'fit-content',
                [`@media (max-width: ${BREAK_POINT}px)`]: {
                  maxWidth: '60rem',
                },
                [`@media (max-width: 1270px)`]: {
                  maxWidth: '40rem',
                },
              }}
            >
              {title}
            </Typo>
            {isNew && <NewBadge />}
          </Flex>
        </Flex>
        <Typo className='notice-info' css={{ fontWeight: 400, width: '10rem', textAlign: 'right' }}>
          {createdDate}
        </Typo>
      </StyledNoticeItemLink>
    </li>
  );
}

export default NoticeItem;
