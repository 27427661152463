import { Flex, Typo } from '@teampetfriends/react-ui';

import { thousandCommaWithZero } from '@utils/util';

interface InlineItemProps {
  label: string;
  value: string | number;
  unit: '건' | '원';
  onClick?: () => void;
}

function InlineItem({ label, value, unit, onClick }: InlineItemProps) {
  return (
    <Flex
      as='li'
      align='center'
      justify='between'
      css={{ cursor: 'pointer', '&:hover': { '& *': { color: '#FF4081' } } }}
      onClick={onClick}
    >
      <Typo css={{ fontSize: '1.4rem', fontWeight: 500 }}>{label}</Typo>
      <Typo css={{ fontSize: '1.4rem', fontWeight: 500 }}>
        <Typo as='b' css={{ fontWeight: 700 }}>
          {thousandCommaWithZero(value)}
        </Typo>
        &nbsp; {unit}
      </Typo>
    </Flex>
  );
}

export default InlineItem;
