import dayjs from 'dayjs';

const formattingDate = (date: Date, format: string) => dayjs(date).format(format);

export const getCurrentMonthRange = (format: string) => {
  const today = new Date();

  const year = today.getFullYear();
  const month = today.getMonth();

  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);

  return `${formattingDate(firstDay, format)} ~ ${formattingDate(lastDay, format)}`;
};
