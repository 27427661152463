import { createPortal } from 'react-dom';

import { Flex, Typo } from '@teampetfriends/react-ui';

import './loading.css';

function LoadingIndicator() {
  return (
    <Flex
      id='loadingIndicator'
      align='center'
      justify='center'
      css={{
        position: 'fixed',
        zIndex: 100, // 제일 우선순위가 높아야 함.
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backdropFilter: 'blur(.15rem)',
        backgroundColor: 'rgba(1, 1, 1, .2)',
        display: 'none',
      }}
    >
      <Flex
        direction='column'
        align='center'
        justify='center'
        css={{
          width: '32rem',
          borderRadius: '1rem',
          height: '14rem',
          backgroundColor: 'rgba(1, 1, 1, .5)',
        }}
      >
        <Typo
          id='loadingText'
          css={{ fontSize: '1.6rem', fontWeight: 500, color: 'var(--color-white)' }}
        />
        <div className='lds-ellipsis'>
          <div />
          <div />
          <div />
          <div />
        </div>
      </Flex>
    </Flex>
  );
}

export default () => createPortal(<LoadingIndicator />, document.body);
