import type { ProductInquireParams } from './interfaces';

export const PRODUCT_INQUIRE_INITIAL_STATE: ProductInquireParams = {
  productStockType: '',
  searchCondition: 'PRODUCT_NAME',
  searchContents: '',
  productStatues: null,
  pageSize: 10,
  pageNumber: 1,
  productGroup1Id: '',
  productGroup2Id: '',
  productGroup3Id: '',
  productGroup4Id: '',
};
