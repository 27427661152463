import { type UseMutationOptions, useMutation } from '@tanstack/react-query';
import { client } from '@utils/client';

export const putNotificationRead = async ({ notificationId }: { notificationId: number }) => {
  const { data } = await client(false).put(`/portals/notifications/${notificationId}/read`);

  return data;
};

export const useNotificationRead = (options?: UseMutationOptions<string, unknown, unknown>) => {
  return useMutation(putNotificationRead, options);
};

export const putNotificationAllRead = async () => {
  const { data } = await client(true).put('/portals/notifications/read');

  return data;
};

export const useNotificationAllRead = (options?: UseMutationOptions<string, unknown, void>) => {
  return useMutation(putNotificationAllRead, options);
};
