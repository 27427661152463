import qs from 'query-string';

const DATE_SIGNAL = 'GMT';

/**
 *
 * @description 객체를 쿼리스트링으로 변환
 */
export const convertObjectToQueryString = (data: Record<string, any>) => {
  return qs.stringify(data, { sort: false, arrayFormat: 'index' });
};

/**
 *
 * @description 쿼리스트링을 객체로 변환
 */
export const convertQueryStringToObject = <T>(queryString: string) => {
  const queryParams = qs.parse(queryString, {
    sort: false,
    arrayFormat: 'index',
    parseBooleans: true,
    parseNumbers: true,
    types: {
      searchContents: 'string',
    },
  }) as Record<string, any>;

  Object.keys(queryParams).forEach((key) => {
    const value = queryParams[key];

    // 날짜인 경우
    if (typeof value === 'string' && value.includes(DATE_SIGNAL)) {
      queryParams[key] = new Date(value);
    }

    // 배열인 경우
    if (typeof value === 'object' && value !== null) {
      const isArray = Object.keys(value).every((subKey) => !Number.isNaN(Number(subKey)));

      if (isArray) {
        queryParams[key] = Object.values(value);
      }
    }
  });

  return queryParams as T;
};

/**
 *  @description 쿼리스트링 -> 객체 / 객체 -> 쿼리스트링 변환 모음
 */
export const genConvertQueryString = <T extends Record<string, any>>(params: string | T) => {
  const result = {
    queryString: '',
    queryObject: {} as T,
  };

  if (typeof params === 'string') {
    result.queryString = params;
    result.queryObject = convertQueryStringToObject(params);
  } else {
    result.queryString = convertObjectToQueryString(params);
    result.queryObject = params;
  }

  return result;
};

/**
 *
 * @description 쿼리스트링과 현재 페이지의 필터 값의 구조가 정확한지 판단하는 함수
 */
export const compareQsAnsDefaultValue = (
  queryString: string,
  defaultValue: Record<string, any>
) => {
  const queryStringToObject = qs.parse(queryString);

  const queryStringKeys = Object.keys(queryStringToObject).sort();
  const defaultValueKeys = Object.keys(defaultValue).sort();

  if (queryStringKeys.length !== defaultValueKeys.length) return false;

  return defaultValueKeys.every((key, index) => key === queryStringKeys[index]);
};
