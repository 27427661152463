import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { getTokenData } from '@utils/token';

import App from './App';

import '@auth/Login/firebase/config';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './index.css';
import '@utils/polyfill';

// sourcemap upload : <bash> npx @sentry/wizard@latest -i sourcemaps
Sentry.init({
  dsn: 'https://baa421fa8ba7f0e89b8119bd776a7aaf@o242719.ingest.sentry.io/4505990466437120',
  integrations: [
    Sentry.breadcrumbsIntegration({
      console: true,
      dom: true,
      fetch: true,
      history: true,
      xhr: true,
    }),
  ],
  environment: import.meta.env.VITE_ENV,
  tracesSampleRate: import.meta.env.VITE_ENV === 'production' ? 1.0 : 0.1,
  beforeSend: (event) => {
    const searchParams = new URLSearchParams(window.location.search);
    const checkUser = getTokenData();

    if (searchParams.size > 1)
      for (const [key, value] of searchParams) {
        Sentry.setTag(key, value || 'null');
      }

    Sentry.setTag('email', checkUser?.user_account);
    Sentry.setTag('role', checkUser?.allowed_types.join(', '));

    return event;
  },
});

const root = ReactDOM.createRoot(
  // NOTE: 테스트 환경을 위한 || 연산자
  (document.getElementById('root') as HTMLElement) || document.createElement('div')
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);
