const loadingInstance = () => {
  const loadingBar = document.getElementById('loadingIndicator');
  const loadingText = document.getElementById('loadingText');

  return {
    open: (text?: string) => {
      if (loadingBar && loadingText) {
        loadingBar.style.display = 'flex';
        loadingText.textContent = text ?? '데이터를 불러오는 중입니다.';
      }
    },
    close: () => {
      if (loadingBar) {
        loadingBar.style.display = 'none';
      }
    },
  };
};

export const loadingControl = loadingInstance;
