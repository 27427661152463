import { createColumnHelper, useReactTable } from '@tanstack/react-table';

import type { UseTableOptionProps } from '../type';

export const columnHelper = <T>() => createColumnHelper<T>();

export const useTableOptions = <T>({
  columns,
  data,
  getCoreRowModel,
  ...options
}: UseTableOptionProps<T>) => {
  return useReactTable({
    columns,
    data,
    getCoreRowModel,
    ...options,
  });
};
