import { Box } from '@teampetfriends/react-ui';

import { useAtomValue } from 'jotai';

import { AlarmBell } from '@icons/svg';
import * as Popover from '@radix-ui/react-popover';
import { alarmCountAtom } from '@store/atoms/alarm';

function AlarmTrigger() {
  const alarmCount = useAtomValue(alarmCountAtom);

  return (
    <Popover.Trigger>
      <Box
        css={{
          width: '2.4rem',
          height: '2.4rem',
          position: 'relative',
        }}
      >
        <AlarmBell />
        {alarmCount > 0 && (
          <Box
            css={{
              background: '#FF4081',
              width: '.6rem',
              height: '.6rem',
              borderRadius: '50%',
              position: 'absolute',
              top: '.2rem',
              right: '.3rem',
            }}
          />
        )}
      </Box>
    </Popover.Trigger>
  );
}

export default AlarmTrigger;
