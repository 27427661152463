import type { Nullable } from '@teampetfriends/util-types';

import dayjs from 'dayjs';

const MONTH_FORMAT = 'YYYY-MM';

export const getCurrentMonth = () => dayjs(new Date()).format(MONTH_FORMAT);

export const checkVendorId = (vendorId: number): Nullable<number> => {
  return vendorId === Number.MAX_SAFE_INTEGER ? null : vendorId;
};

const reduceMonth =
  (target: string, keyword: 'subtract' | 'add') => (acc: string[], cur: number) => {
    const targetMonth = dayjs(new Date(target))[keyword](cur, 'month').format(MONTH_FORMAT);

    acc = keyword === 'add' ? [...acc, targetMonth] : [targetMonth, ...acc];

    return acc;
  };

const createMonthList = (leng: number) => Array.from({ length: leng }, (_, i) => i + 1);

export const getYearMonthList = ({ prev, next }: { prev: number; next: number }) => {
  const target = dayjs(new Date()).format(MONTH_FORMAT);

  const prevList = createMonthList(prev).reduce(reduceMonth(target, 'subtract'), [target]);
  const nextList = createMonthList(next).reduce(reduceMonth(target, 'add'), [target]);

  const result = new Set([...prevList, target, ...nextList]);

  return Array.from(result);
};
