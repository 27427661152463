import { Flex, Typo } from '@teampetfriends/react-ui';

function EmptyList() {
  return (
    <Flex css={{ height: '60rem' }} align='center' justify='center'>
      <Typo
        css={{
          color: '#9CA1AA',
          fontSize: '1.6rem',
          fontWeight: 500,
        }}
      >
        데이터가 없습니다.
      </Typo>
    </Flex>
  );
}

export default EmptyList;
