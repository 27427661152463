import { useCallback, useEffect, useRef } from 'react';

import { Callback } from './type';

/**
 *
 * @description 디바운스 적용 시, 콜백 함수 보존용
 */
export const usePreservedCallback = <T extends Callback>(callback: T) => {
  const callbackFunc = useRef<T>(callback);

  const result = useCallback((...args: any[]) => {
    return callbackFunc.current(...args);
  }, []);

  useEffect(() => {
    callbackFunc.current = callback;
  }, [callback]);

  return result as T;
};
