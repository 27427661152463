import { useNavigate } from 'react-router-dom';

import { Flex, Typo } from '@teampetfriends/react-ui';

function ErrorRenderFallback({ reset }: { reset: () => void }) {
  const navigate = useNavigate();

  const onReset = () => {
    reset();
    navigate('/dashboard', { replace: true });
  };

  return (
    <Flex
      align='center'
      justify='center'
      css={{ background: 'inherit', width: '100%', height: '100%' }}
    >
      <Flex direction='column' css={{ rowGap: '1rem' }} as='button' onClick={onReset}>
        <Typo css={{ color: '#C6C9CE', fontSize: '1.8rem', fontWeight: 700 }}>
          페이지를 찾을 수 없습니다.
        </Typo>
        <Typo css={{ color: '#C6C9CE', fontSize: '1.6rem', fontWeight: 400 }}>대시보드로 이동</Typo>
      </Flex>
    </Flex>
  );
}

export default ErrorRenderFallback;
