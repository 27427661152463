import { Box, Flex } from '@teampetfriends/react-ui';

import { getTokenData } from '@utils/token';

import AlarmSection from './modules/components/AlarmSection';
import ClaimSection from './modules/components/ClaimSection';
import DeliverySection from './modules/components/DeliverySection';
import NoticeSection from './modules/components/NoticeSection/NoticeSection';
import RequestSection from './modules/components/RequestSection';
import SalesRankingSection from './modules/components/SalesRankingSection';
import SettlementSection from './modules/components/SettlementSection';

export const BREAK_POINT = 1570;

function AdminDashboard() {
  return (
    <>
      <RequestSection />
      <Box
        css={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, minmax(580px, auto))',
          gap: '1.6rem',
          [`@media (max-width: ${BREAK_POINT}px)`]: {
            gridTemplateColumns: '1fr',
          },
        }}
      >
        <SettlementSection />
        <NoticeSection />
      </Box>
      <SalesRankingSection />
    </>
  );
}

function VendorDashboard() {
  return (
    <>
      <Box
        css={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, minmax(580px, 50%))',
          gap: '1.6rem',
          [`@media (max-width: ${BREAK_POINT}px)`]: {
            gridTemplateColumns: '1fr',
          },
        }}
      >
        <AlarmSection />

        <Box
          css={{
            display: 'grid',
            gridTemplateRows: '.4fr 1.6fr',
            gap: '1.6rem',
            height: '63.2rem',
          }}
        >
          <Box
            css={{
              display: 'grid',
              gap: '1.6rem',
              height: '100%',
              gridTemplateColumns: '1fr 1fr',
            }}
          >
            <DeliverySection />
            <ClaimSection />
          </Box>
          <NoticeSection />
        </Box>
      </Box>
      <SettlementSection />
      <SalesRankingSection />
    </>
  );
}

function Dashboard() {
  const checkUser = getTokenData();

  return (
    <Flex direction='column' css={{ gap: '1.6rem' }} wrap='wrap'>
      {checkUser?.is_vendor ? <VendorDashboard /> : <AdminDashboard />}
    </Flex>
  );
}

export default Dashboard;
