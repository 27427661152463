import { ReactNode } from 'react';

import { CSS, styled } from '@teampetfriends/react-ui';

import * as Tooltip from '@radix-ui/react-tooltip';

const StyledToolTipContent = styled(Tooltip.Content);
const StyledToolTipArrow = styled(Tooltip.Arrow);

function ToolTipWrapper({
  children,
  root,
  provider,
}: {
  children: ReactNode;
  root?: Tooltip.TooltipProps;
  provider?: Tooltip.TooltipProviderProps;
}) {
  return (
    <Tooltip.Provider {...provider}>
      <Tooltip.Root {...root}>{children}</Tooltip.Root>
    </Tooltip.Provider>
  );
}

function ToolTipTrigger({
  children,
  ...rest
}: { children: ReactNode } & Tooltip.TooltipTriggerProps) {
  return <Tooltip.Trigger {...rest}>{children}</Tooltip.Trigger>;
}

function ToolTipContent({
  children,
  contentCss,
  ...rest
}: { children: ReactNode; contentCss?: CSS } & Tooltip.TooltipContentProps) {
  return (
    <Tooltip.Portal>
      <StyledToolTipContent css={contentCss} {...rest}>
        {children}
      </StyledToolTipContent>
    </Tooltip.Portal>
  );
}

function ToolTipArrow({ css }: { css?: CSS }) {
  return <StyledToolTipArrow css={css} />;
}

const Wrapper = ToolTipWrapper;
const Trigger = ToolTipTrigger;
const Content = ToolTipContent;
const Arrow = ToolTipArrow;

export { Arrow, Content, Trigger, Wrapper };
