import type { StocksInquireParams } from './interfaces';

export const STOCKS_INQUIRE_INITIAL_STATE: StocksInquireParams = {
  centerId: 12,
  stockStatus: '',
  searchCondition: 'PRODUCT_ITEM_NAME',
  searchContents: '',
  pageNumber: 1,
  pageSize: 10,
  productGroup1Id: '',
  productGroup2Id: '',
  productGroup3Id: '',
  productGroup4Id: '',
};
