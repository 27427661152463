import { ReactNode, useMemo } from 'react';

import { CSS, Flex } from '@teampetfriends/react-ui';

import { useNotificationContext } from '../context/createNotificationContext';

interface NotificationListProps<T> {
  list: T[];
  renderElement: (item: T) => ReactNode;
  filterKey?: keyof T;
  css?: CSS;
  emptyElement?: ReactNode;
}

function NotificationList<T>({
  renderElement,
  filterKey,
  list,
  css,
  emptyElement,
}: NotificationListProps<T>) {
  const { tab } = useNotificationContext('NotificationList');

  const filteringList = useMemo(() => {
    if (tab === '') return list;

    if (!filterKey) return list;

    return list.filter((item) => item[filterKey] === tab);
  }, [list, tab, filterKey]);

  if (!filteringList.length) return emptyElement;

  return (
    <Flex
      as='ul'
      direction='column'
      css={{ mt: '2rem', rowGap: '1.6rem', height: '43rem', overflowY: 'scroll', ...css }}
    >
      {filteringList.map(renderElement)}
    </Flex>
  );
}

export default NotificationList;
