/* eslint-disable no-async-promise-executor */
import type { Nullable } from '@teampetfriends/util-types';

import { authStore } from '@hooks/useSyncAuth';

import { axiosInstance } from './client';
import { redirectToLoginPage, useRefreshTokenSetting } from './token';

let refreshInterceptorDebounce: Nullable<ReturnType<typeof setTimeout>> = null;
let incorrectPermissionDebounce: Nullable<ReturnType<typeof setTimeout>> = null;

const originalRequests: any[] = [];

export const tokenRefreshInterceptor: () => Promise<
  { accessToken?: string } | undefined
> = async () => {
  try {
    const tokens = await useRefreshTokenSetting();

    const currentTokens = { accessToken: tokens?.accessToken };

    authStore.update(currentTokens);

    return currentTokens;
  } catch (err) {
    authStore.remove();
    redirectToLoginPage();
  }
};

export const tokenRefreshInterceptorWithDebounce = (config: any) => {
  const originalRequest = config;

  originalRequests.push(originalRequest);

  if (refreshInterceptorDebounce) clearTimeout(refreshInterceptorDebounce);

  refreshInterceptorDebounce = setTimeout(async () => {
    try {
      const tokens = await useRefreshTokenSetting();

      const currentTokens = { accessToken: tokens?.accessToken };

      authStore.update(currentTokens);

      const requestPromises = originalRequests.map(async (originalRequest) => {
        return axiosInstance().request(originalRequest);
      });

      await Promise.all(requestPromises).then(() => {
        originalRequests.splice(0);
        window.location.reload();
      });
    } catch (err) {
      authStore.remove();
      redirectToLoginPage();
    }
  }, 500);
};

export const incorrectPermissionInterceptor = () => {
  if (incorrectPermissionDebounce) clearTimeout(incorrectPermissionDebounce);

  incorrectPermissionDebounce = setTimeout(() => {
    alert(`권한이 변경되었습니다.\n재로그인 후 확인 부탁드립니다.`);
    authStore.remove();
    redirectToLoginPage();
  }, 500);
};
