import { type ReactNode } from 'react';

import { atom, useSetAtom } from 'jotai';

export type ModalKey =
  | '배송상태변경'
  | '대량배송엑셀업로드'
  | '출고지연안내'
  | '배송완료처리'
  | '배송중변경'
  | '주문취소불가'
  | '취소접수'
  | '상품기본정보'
  | '판매가격'
  | '상품이미지'
  | '상품상세이미지'
  | '상품정보고시'
  | '연관검색'
  | '상품요청승인'
  | '상품반려승인'
  | '판매가격변경' // 상품요청관리
  | '판매상태변경' // 상품요청관리
  | '상품단건검색' // 상품수정요청
  | '상품다건검색' // 상품수정요청
  | '신규판매가격변경요청' // 상품수정요청
  | '신규판매상태변경요청' // 상품수정요청
  | '판매가격변경요청' // 상품수정요청
  | '판매상태변경요청' // 상품수정요청
  | '상품상세'
  | '요청취소'
  | '상품등록취소'
  | '상세이미지미리보기'
  | '입고요청상세'
  | '입고요청승인'
  | '입고요청반려'
  | '입고요청회송'
  | '입고요청등록'
  | '입고요청등록요청'
  | '정산마감확인요청'
  | '결제대금업로드'
  | '대표상품선택'
  | '패키지상품변경확인'
  | '패키지상품취소'
  | '품목검색'
  | '기타내역삭제'
  | '교환반품접수승인처리'
  | '교환반품접수반려처리'
  | '재배송장등록'
  | '수거송장입력'
  | '환불완료처리'
  | '재배송장추가수정'
  | '교환반품이미지상세'
  | '쿠폰상세'
  | '쿠폰상품추가'
  | '전월휴대폰결제'
  | '쿠폰정보이미지'
  | '쿠폰활성화'
  | '쿠폰비활성화'
  | '쿠폰요청취소'
  | '쿠폰승인완료'
  | '쿠폰승인반려'
  | '쿠폰제외안내'
  | '쿠폰발행취소';

export type SizeKey = 'alert' | 'm' | 'm2' | 'm3' | 'l' | 'preview' | 'preview2';

export interface ModalState {
  id: ModalKey;
  title?: ReactNode | string | undefined;
  content: ReactNode | undefined;
  open: boolean;
  size?: SizeKey;
}

export type OpenModalParams = Omit<ModalState, 'open' | 'id'>;

export const modalAtom = atom<ModalState[]>([]);

export const useModal = (key: ModalKey) => {
  const setModals = useSetAtom(modalAtom);

  const openModal = ({ title, content, size = 'm' }: OpenModalParams) => {
    setModals((prev) => {
      if (prev.find((it) => it.id === key)) {
        return prev.map((modal) =>
          modal.id === key ? { ...modal, title, content, open: true } : modal
        );
      }
      const newModal = { id: key, title, content, open: true, size };
      return [...prev, newModal];
    });
  };

  const closeModal = () =>
    setModals((prev) =>
      prev.map((modal) => (modal.id === key ? { ...modal, open: false } : modal))
    );

  return { openModal, closeModal };
};
