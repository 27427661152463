import type {
  AttributeItem,
  CategoryItem,
  ParsingAttributeItem,
  ParsingCategoryItem,
} from './type';

export const createLabel = ({
  group1Name,
  group2Name,
  group3Name,
  group4Name,
}: {
  group1Name: string;
  group2Name: string;
  group3Name: string;
  group4Name?: string;
}) => {
  let label = `${group1Name}`;

  if (group2Name) label += ` > ${group2Name}`;
  if (group3Name) label += ` > ${group3Name}`;
  if (group4Name) label += ` > ${group4Name}`;

  return label;
};

export const parsingAttributesCheckbox = (
  attributes: AttributeItem[]
): Record<string, ParsingAttributeItem[]> => {
  const obj: Record<string, ParsingAttributeItem[]> = {};

  attributes.forEach((item) => {
    const objKey = `${item.productGroup1Name}/${item.productGroup2Name}/${item.productAttributeGroup1Name}`;

    if (!obj[objKey]) obj[objKey] = [];

    obj[objKey].push({
      ...item,
      value: item.productAttributeGroup2Id,
      label: createLabel({
        group1Name: item.productGroup1Name,
        group2Name: item.productAttributeGroup1Name,
        group3Name: item.productAttributeGroup2Name,
      }),
      fakeLabel: item.productAttributeGroup2Name,
    });
  });

  return obj;
};

export const mappingAttributes = (attributes: ParsingAttributeItem[]): ParsingAttributeItem[] => {
  return attributes.map((item) => {
    return {
      ...item,
      label: createLabel({
        group1Name: item.productGroup1Name,
        group2Name: item.productAttributeGroup1Name,
        group3Name: item.productAttributeGroup2Name,
      }),
      value: item.productAttributeGroup2Id,
    };
  });
};

export const parsingCategoryCheckbox = (
  categories: CategoryItem[]
): Record<string, ParsingCategoryItem[]> => {
  const obj: Record<string, ParsingCategoryItem[]> = {};

  categories.forEach((item) => {
    const objKey = `${item.productGroup1Name}/${item.productGroup2Name}`;

    if (!obj[objKey] || !obj[objKey].at(-1)) obj[objKey] = [];

    if (obj[objKey].at(-1)?.productGroup3Name !== item.productGroup3Name) {
      obj[objKey].push({
        ...item,
        fakeLabel: item.productGroup3Name,
        label: createLabel({
          group1Name: item.productGroup1Name,
          group2Name: item.productGroup2Name,
          group3Name: item.productGroup3Name,
        }),
      });
    }
  });

  return obj;
};

export const mappingCategories = (categories: CategoryItem[]): ParsingCategoryItem[] => {
  return categories.map((item) => {
    return {
      ...item,
      fakeLabel: createLabel({
        group1Name: item.productGroup1Name,
        group2Name: item.productGroup2Name,
        group3Name: item.productGroup3Name,
      }),
      label: createLabel({
        group1Name: item.productGroup1Name,
        group2Name: item.productGroup2Name,
        group3Name: item.productGroup3Name,
      }),
    };
  });
};
