import { Nullable } from '@teampetfriends/util-types';

import { atom, useAtom } from 'jotai';

import { useQuery } from '@tanstack/react-query';
import { client } from '@utils/client';

import type { AttributeItem, CategoryAndAttribute, CategoryItem } from './type';
import {
  mappingAttributes,
  mappingCategories,
  parsingAttributesCheckbox,
  parsingCategoryCheckbox,
} from './utils';

export const categoryAnaAttributeAtom = atom<Nullable<CategoryAndAttribute>>(null);

export const getProductAttrList = async () => {
  const { data } = await client(false).get<{
    attributes: AttributeItem[];
    categories: CategoryItem[];
  }>('/products/attributes/categories');

  return data;
};

export const useCategoryGroup = () => {
  const [categories, setCategories] = useAtom(categoryAnaAttributeAtom);

  useQuery({
    queryKey: ['groupAttrAll'],
    queryFn: getProductAttrList,
    enabled: !categories,
    select: (res) => {
      const { attributes, categories } = res;
      const attrCheckBox = parsingAttributesCheckbox(attributes);
      const attrList = mappingAttributes(Object.values(attrCheckBox).flat());

      const categoryCheckbox = parsingCategoryCheckbox(categories);
      const categoryList = mappingCategories(Object.values(categoryCheckbox).flat());

      return {
        attr: {
          checkboxes: attrCheckBox,
          list: attrList,
        },
        category: {
          checkboxes: categoryCheckbox,
          list: categoryList,
        },
        totals: res.categories,
      };
    },
    onSuccess: (res) => {
      setCategories(res);
    },
  });

  return (
    categories ?? {
      attr: { checkboxes: {}, list: [] },
      category: { checkboxes: {}, list: [] },
      totals: [],
    }
  );
};
