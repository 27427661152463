import Table, { useTableOptions } from '@components/Table';
import { useSalesRankingList } from '@dashboard/modules/apis';
import { getCoreRowModel } from '@tanstack/react-table';

import { useSalesRankingContext } from '../context/createSalesRankingContext';
import EmptyList from '../EmptyList';

import { getVendorSalesRankingColumns } from './columns';

function VendorRankingTable() {
  const { sortCondition } = useSalesRankingContext('VendorRankingTable');

  const { rankingList } = useSalesRankingList({
    sortCondition,
    checkUser: 'VENDOR',
  });

  const table = useTableOptions({
    data: rankingList,
    columns: getVendorSalesRankingColumns(),
    getCoreRowModel: getCoreRowModel(),
  });

  return <Table {...table} emptyNode={<EmptyList />} />;
}

export default VendorRankingTable;
