import { getCalcDate } from '@utils/util';

export const DELIVERY_STATUS_LIST = Object.freeze([
  {
    id: 19,
    label: '결제완료',
    summaryKey: 'orderCompletedCount',
  },
  {
    id: 20,
    label: '배송 준비중',
    summaryKey: 'deliveryDraftCount',
  },
  {
    id: 21,
    label: '배송중',
    summaryKey: 'deliveryProcessCount',
  },
  {
    id: 29,
    label: '배송완료',
    summaryKey: 'deliveryCompleteCount',
  },
]);

export const DELIVERY_MANAGE_INITIAL_STATE = {
  startDate: getCalcDate(new Date(), 14, 'day'),
  endDate: new Date(),
  searchContents: '',
  searchCondition: 'PRODUCT_NAME',
  pageSize: 10,
  pageNumber: 1,
  endPointKey: 19,
  delayedDelivery: 'N',
};

export const DELIVERY_MANAGE_INITIAL_PARAMS = {
  startDate: getCalcDate(new Date(), 14, 'day'),
  endDate: new Date(),
  searchContents: '',
  searchCondition: 'PRODUCT_NAME',
  pageSize: 10,
  pageNumber: 1,
};

export const MASSIVE_EXCEL_COLUMNS_KEY = {
  주문번호: 'orderId',
  배송상태번호: 'deliveryStatusId',
  판매상품코드: 'productId',
  판매항목코드: 'productItemId',
  옵션코드: 'productItemId',
  택배사코드: 'invoiceCode',
  운송장번호: 'invoiceNo', // 선택값이고 나머지 필수값
};

export const MASSIVE_EXCEL_COLUMNS_STRING_TYPE_KEY = ['택배사', '운송장번호', '택배사코드'];
