import { ReactNode, useEffect, useState } from 'react';

import { styled } from '@teampetfriends/react-ui';
import { Cookie, jwtDecode } from '@teampetfriends/utils';

import Aside from '@commons/components/Aside';
import Header from '@commons/components/Header';
import ErrorBoundary from '@components/ErrorBoundary';
import { useInterval } from '@hooks/useInterval';
import { useAuthInfo } from '@hooks/useSyncAuth';
import ErrorRenderFallback from '@layouts/ErrorRenderFallback';
import NoticeCouponContents from '@layouts/NoticeModal/contents/Coupon';
import NoticeModal from '@layouts/NoticeModal/NoticeModal';
import { type TokenInfo, getTokenData, getTokens } from '@utils/token';

const MainWrapper = styled('main', {
  marginTop: '5.7rem',
  overflowY: 'scroll',
  transition: 'all .3s',
  height: 'calc(100vh - 5.7rem)',
  padding: '3rem 5rem 7.5rem',
});

const NOTICE_COOKIE_NAME = 'pf-notice-timer';

const calculateExpirationDate = () => {
  const currentDate = new Date();
  currentDate.setHours(23, 59, 59, 999);
  return currentDate;
};

function DefaultLayout({ children }: { children: ReactNode }) {
  const { storage, update } = useAuthInfo(['accessToken']);
  const checkUser = getTokenData();

  const [isOpen, setIsOpen] = useState(true);

  const [noticeOpen, setNoticeOpen] = useState(false);

  const onClickClose = () => setNoticeOpen(false);

  const onClickDayClose = () => {
    const expireTime = calculateExpirationDate();
    Cookie.setCookie(NOTICE_COOKIE_NAME, `${expireTime}`, { expires: expireTime });
    setNoticeOpen(false);
  };

  useEffect(() => {
    if (!checkUser?.is_vendor) return;
    const isNoticeOpenCookieInclude = Cookie.getCookie(NOTICE_COOKIE_NAME);
    if (isNoticeOpenCookieInclude) return;
    // setNoticeOpen(true); // NOTE: 공지가 필요할떄
    setNoticeOpen(false); // NOTE: 공지가 필요없을떄
  }, []);

  useInterval(() => {
    const [accessToken] = getTokens(['accessToken']);

    const userInfo = accessToken ? jwtDecode<TokenInfo>(accessToken) : null;
    const storageInfo = storage ? jwtDecode<TokenInfo>(storage) : null;

    if (accessToken && storageInfo && userInfo?.user_id !== storageInfo.user_id)
      update({ accessToken });
  }, 1000);

  return (
    <div>
      <Aside isOpen={isOpen} />
      <Header action={() => setIsOpen((prev) => !prev)} isOpen={isOpen} />
      <MainWrapper
        id='main'
        css={{
          position: 'relative',
          marginLeft: isOpen ? 251 : 0,
        }}
      >
        <ErrorBoundary
          renderFallback={(fallbackProps) => <ErrorRenderFallback reset={fallbackProps.reset} />}
        >
          {children}

          {noticeOpen && (
            <NoticeModal onClose={onClickClose} onDayClose={onClickDayClose}>
              <NoticeCouponContents />
            </NoticeModal>
          )}
        </ErrorBoundary>
      </MainWrapper>
    </div>
  );
}

export default DefaultLayout;
