import { useState } from 'react';

import { useNotificationList } from '@dashboard/modules/components/AlarmSection/modules/apis';
import * as Popover from '@radix-ui/react-popover';

import AlarmContent from './modules/components/AlarmContent';
import AlarmTrigger from './modules/components/AlarmTrigger';

function Alarm() {
  const [open, setOpen] = useState(false);

  const closePopOver = () => setOpen(false);

  useNotificationList();

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <AlarmTrigger />
      <AlarmContent onClose={closePopOver} />
    </Popover.Root>
  );
}

export default Alarm;
