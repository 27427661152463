import { type CellContext } from '@tanstack/react-table';

export const getValue = <T extends object>(data: CellContext<T, unknown>) => data.getValue();

export const getValueWithNullable = <T extends object>(data: CellContext<T, unknown>) =>
  data.getValue() || '-';

export const getValueToLocaleString = <T extends object>(data: CellContext<T, unknown>) => {
  const cellValue = data.getValue();

  return cellValue ? Number(cellValue).toLocaleString() : '';
};

export const getValueToLocaleStringWithNullable = <T extends object>(
  data: CellContext<T, unknown>
) => {
  const cellValue = data.getValue();

  return cellValue ? Number(cellValue).toLocaleString() : '-';
};
