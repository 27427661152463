import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import BackgroundLayout from '@auth/components/BackgroundLayout';
import { useAuthInfoAction } from '@hooks/useSyncAuth';
import { getTokenData } from '@utils/token';

import PasswordForm from './components/PasswordForm';

function FindPassword() {
  const navigate = useNavigate();
  const { remove } = useAuthInfoAction();

  const checkUser = getTokenData();

  useEffect(() => {
    if (!checkUser?.is_vendor) {
      remove();
      navigate('/login', { replace: true });
    }
  }, []);

  if (checkUser?.password_reset) return <Navigate to='/' replace />;

  return (
    <BackgroundLayout>
      <PasswordForm />
    </BackgroundLayout>
  );
}

export default FindPassword;
