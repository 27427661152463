import { Flex, Typo } from '@teampetfriends/react-ui';

import * as ToolTip from '@commons/components/ToolTip';
import { CellProps } from '@components/Table';
import { Help, Info } from '@icons/svg';

const DELAY_DURATION = 100;

export function SortAdminTooltip() {
  return (
    <ToolTip.Wrapper root={{ delayDuration: DELAY_DURATION }}>
      <ToolTip.Trigger>
        <Help />
      </ToolTip.Trigger>
      <ToolTip.Content
        side='bottom'
        sideOffset={10}
        align='start'
        alignOffset={-50}
        contentCss={{
          border: '.1rem solid #2D3035',
          background: 'var(--color-white)',
          padding: '2rem',
        }}
      >
        <Flex direction='column' css={{ rowGap: '1.6rem' }}>
          <Typo as='h6' css={{ fontSize: '1.4rem', fontWeight: 700 }}>
            순위 기준
          </Typo>
          <Flex as='ul' direction='column' css={{ rowGap: '1.4rem', marginLeft: '1.5rem' }}>
            <Typo as='li' css={{ whiteSpace: 'pre-wrap', listStyle: 'disc' }}>
              {`최근 15일 간의 주문이 집계되며 취소 및 교환/반품은 반영되지\n않습니다.`}
            </Typo>
            <Typo as='li' css={{ whiteSpace: 'pre-wrap', listStyle: 'disc' }}>
              주문수 : 총 주문건수가 많은 거래처 기준으로 노출됩니다.
            </Typo>
            <Typo as='li' css={{ whiteSpace: 'pre-wrap', listStyle: 'disc' }}>
              결제가 : 총 상품 결제가가 높은 거래처 기준으로 노출됩니다.
            </Typo>
            <Typo as='li' css={{ whiteSpace: 'pre-wrap', listStyle: 'disc' }}>
              마진 : 펫프렌즈의 마진이 높은 거래처 기준으로 노출됩니다.
            </Typo>
          </Flex>
        </Flex>
      </ToolTip.Content>
    </ToolTip.Wrapper>
  );
}

export function SortVendorTooltip() {
  return (
    <ToolTip.Wrapper root={{ delayDuration: DELAY_DURATION }}>
      <ToolTip.Trigger>
        <Help />
      </ToolTip.Trigger>
      <ToolTip.Content
        side='bottom'
        sideOffset={10}
        align='start'
        alignOffset={-50}
        contentCss={{
          border: '.1rem solid #2D3035',
          background: 'var(--color-white)',
          padding: '2rem',
        }}
      >
        <Flex direction='column' css={{ rowGap: '1.6rem' }}>
          <Typo as='h6' css={{ fontSize: '1.4rem', fontWeight: 700 }}>
            순위 기준
          </Typo>
          <Flex as='ul' direction='column' css={{ rowGap: '1.4rem', marginLeft: '1.5rem' }}>
            <Typo as='li' css={{ whiteSpace: 'pre-wrap', listStyle: 'disc' }}>
              {`최근 15일 간의 주문이 집계되며 취소 및 교환/반품은 반영되지\n않습니다.`}
            </Typo>
            <Typo as='li' css={{ whiteSpace: 'pre-wrap', listStyle: 'disc' }}>
              주문수 : 옵션의 주문건수가 많은 기준으로 노출됩니다.
            </Typo>
            <Typo as='li' css={{ whiteSpace: 'pre-wrap', listStyle: 'disc' }}>
              판매수량 : 옵션의 판매수량이 많은 기준으로 노출됩니다.
            </Typo>
            <Typo as='li' css={{ whiteSpace: 'pre-wrap', listStyle: 'disc' }}>
              {`판매금액 : 옵션의 판매수량에 대한 공급가 총합이 높은 기준으로 \n노출됩니다.`}
            </Typo>
          </Flex>
        </Flex>
      </ToolTip.Content>
    </ToolTip.Wrapper>
  );
}

export function SellingPriceTooltip() {
  return (
    <ToolTip.Wrapper root={{ delayDuration: DELAY_DURATION }}>
      <ToolTip.Trigger>
        <Info />
      </ToolTip.Trigger>
      <ToolTip.Content
        side='top'
        sideOffset={10}
        contentCss={{
          background: '#2f3337d9',
          padding: '2rem',
          borderRadius: '.7rem',
        }}
      >
        <Flex direction='column' css={{ rowGap: '1.2rem' }}>
          <Typo as='h6' css={{ fontSize: '1.4rem', fontWeight: 700, color: 'var(--color-white)' }}>
            판매금액
          </Typo>
          <Typo css={{ fontWeight: 500, color: 'var(--color-white)' }}>
            옵션값 공급가액 * 판매수량
          </Typo>
        </Flex>

        <ToolTip.Arrow css={{ fill: '#2f3337d9' }} />
      </ToolTip.Content>
    </ToolTip.Wrapper>
  );
}

export function EllipsisTextWithTooltipCell<T>({ row, column }: CellProps<T>) {
  const value = row.getValue(column.id);

  const rowValue = (value: unknown) => {
    if (!value) return '-';

    return `${value}`;
  };

  if (!value) {
    return <Typo css={{ textAlign: 'center', width: '100%' }}>{rowValue(value)}</Typo>;
  }

  return (
    <Flex align='center'>
      <ToolTip.Wrapper root={{ delayDuration: DELAY_DURATION }}>
        <ToolTip.Trigger asChild>
          <Typo css={{ textAlign: 'center', width: '100%' }}>{rowValue(value)}</Typo>
        </ToolTip.Trigger>
        <ToolTip.Content
          contentCss={{
            padding: '.8rem',
            borderRadius: '.7rem',
            background: '#2F3337F2',
          }}
        >
          <Typo css={{ color: 'var(--color-white)', fontSize: '1.2rem', fontWeight: 500 }}>
            {row.getValue(column.id)}
          </Typo>
          <ToolTip.Arrow css={{ fill: '#2F3337F2' }} />
        </ToolTip.Content>
      </ToolTip.Wrapper>
    </Flex>
  );
}
