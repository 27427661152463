import { CSS, Typo } from '@teampetfriends/react-ui';

export function ErrorSpan({ error, css }: { error?: string | boolean; css?: CSS }) {
  if (!error || (error && typeof error === 'boolean')) return null;
  return (
    <Typo as='span' css={{ color: 'var(--color-red-100)', fontSize: '1.2rem', ...css }}>
      {error}
    </Typo>
  );
}
