import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import BackgroundLayout from '@auth/components/BackgroundLayout';
import { useAuthInfo } from '@hooks/useSyncAuth';

import LoginForm from './components/LoginForm';
import { adminLogin } from './firebase/helper';

function Login() {
  const navigate = useNavigate();
  const {
    storage,
    cookie: [accessToken],
    update,
  } = useAuthInfo(['accessToken']);

  const onAdminLogin = async () => {
    const result = await adminLogin();

    if (!result) return;

    update({
      accessToken: result.accessToken,
      refreshToken: result.refreshToken,
    });

    navigate('/', { replace: true });
  };

  useEffect(() => {
    if (accessToken) {
      if (!storage) update({ accessToken });
      navigate('/', { replace: true });
    }
  }, [storage]);

  return (
    <BackgroundLayout
      adminLogin={
        <button
          type='button'
          className='absolute top-[1.6rem] right-[3.2rem] text-[1.4rem]'
          onClick={onAdminLogin}
        >
          관리자 로그인
        </button>
      }
    >
      <LoginForm />
    </BackgroundLayout>
  );
}

export default Login;
