import { useCallback } from 'react';

import { Flex } from '@teampetfriends/react-ui';

import { useGetSettlementSummaryWithDashboard } from '@dashboard/modules/apis';
import { useAuthCheck } from '@dashboard/modules/hooks/useAuthCheck';
import { useRoute } from '@hooks/useRoute';
import { getSettlementInitialState } from '@settlement/commons/modules/constant';
import { getTokenData, TokenInfo } from '@utils/token';

import { Board, BoardTitle } from '../Board';

import AdminSettlementItem from './modules/components/AdminSettlementItem';
import VendorSettlementItem from './modules/components/VendorSettlementItem';
import { getCurrentMonthRange } from './modules/utils';

function SettlementSection() {
  const checkUser = getTokenData();

  const { push } = useRoute();

  const checkAuth = useAuthCheck();

  const { data } = useGetSettlementSummaryWithDashboard();
  const { vendorFee, consignmentFee, couponFee } = data;

  const monthRange = getCurrentMonthRange('YYYY.MM.DD');

  const onClickNavigateWithParams = (pathKey: 'vendor' | 'consignment' | 'coupon') => {
    const params = getSettlementInitialState();
    const path = `/${pathKey}-settlement/list`;

    push(path, params);
  };

  const onClickNavigateWithParamsAndVendor = (
    pathKey: 'vendor' | 'consignment' | 'coupon',
    roles: TokenInfo['allowed_types']
  ) => checkAuth(roles, () => onClickNavigateWithParams(pathKey));

  const renderList = useCallback(() => {
    if (checkUser?.is_vendor) {
      return (
        <>
          <VendorSettlementItem
            title='업체배송 정산예정금액'
            value={vendorFee}
            unit='원'
            onClick={() => onClickNavigateWithParamsAndVendor('vendor', ['VENDOR'])}
          />
          <VendorSettlementItem
            title='판매자 심쿵배송 정산예정금액'
            value={consignmentFee}
            unit='원'
            onClick={() => onClickNavigateWithParamsAndVendor('consignment', ['CONSIGNMENT'])}
          />
          <VendorSettlementItem
            title='쿠폰 비용'
            value={couponFee}
            unit='원'
            onClick={() =>
              onClickNavigateWithParamsAndVendor('coupon', ['VENDOR', 'CONSIGNMENT', 'PURCHASE'])
            }
          />
        </>
      );
    }

    return (
      <>
        <AdminSettlementItem
          title={`업체배송\n정산예정금액`}
          value={vendorFee}
          unit='원'
          onClick={() => onClickNavigateWithParams('vendor')}
        />
        <AdminSettlementItem
          title={`판매자 심쿵배송\n정산예정금액`}
          value={consignmentFee}
          unit='원'
          onClick={() => onClickNavigateWithParams('consignment')}
        />
        <AdminSettlementItem
          title='쿠폰 마진'
          value={couponFee}
          unit='원'
          onClick={() => onClickNavigateWithParams('coupon')}
        />
      </>
    );
  }, [data]);

  return (
    <Board css={{ background: '#F9F9FA' }}>
      <BoardTitle title='정산' description={monthRange} css={{ pb: '2rem' }} />
      <Flex as='ul' justify='between' align='center' css={{ columnGap: '1.6rem', mt: '2rem' }}>
        {renderList()}
      </Flex>
    </Board>
  );
}

export default SettlementSection;
