import type { ItemsInquireParams } from '../modules/interfaces';

export const ITEMS_INQUIRE_INITIAL_STATE: ItemsInquireParams = {
  stockStatus: '',
  storageTemperature: '',
  searchCondition: 'PRODUCT_ITEM_NAME',
  searchContents: '',
  pageNumber: 1,
  pageSize: 10,
  productGroup1Id: '',
  productGroup2Id: '',
  productGroup3Id: '',
  productGroup4Id: '',
};
