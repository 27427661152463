import { useCallback } from 'react';

import { jwtDecode } from '@teampetfriends/utils';

import { toastify } from '@components/Toast';
import { useAuthInfo } from '@hooks/useSyncAuth';
import { type TokenInfo, getTokenData } from '@utils/token';

export const useAuthCheck = () => {
  const { storage } = useAuthInfo(['accessToken']);

  const isAllowedIncluded = useCallback((roles: string[], allowedType?: string[]): boolean => {
    return roles.some((element) => allowedType?.includes(element));
  }, []);

  const checkAuth = useCallback(
    (role: TokenInfo['allowed_types'], fn: (args?: any) => void) => {
      const checkUser = storage ? jwtDecode<TokenInfo>(storage) : getTokenData();
      const allowedType = checkUser?.allowed_types ?? [];

      const isAllowedAuth = isAllowedIncluded(allowedType, role);

      if (!isAllowedAuth) {
        toastify(
          <p>
            권한이 없는 메뉴로 진입이 불가합니다. <br />
            권한 추가는 담당MD에게 문의 부탁드립니다.
          </p>,
          {
            type: 'warning',
          }
        );
        return false;
      }

      fn();
    },
    [storage]
  );

  return checkAuth;
};
