import { Link } from 'react-router-dom';

import { Flex, styled, Typo } from '@teampetfriends/react-ui';

import NewBadge from '@notice/NoticeInquire/modules/components/NoticeResult/modules/components/NoticeItem/modules/components/NewBadge';
import { NOTICE_INQUIRE_INITIAL_STATE } from '@notice/NoticeInquire/modules/constant';
import { convertObjectToQueryString } from '@utils/queryString';

import type { TopNoticeItem } from '../interfaces';

const StyledNoticeItemLink = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  columnGap: '1rem',
  cursor: 'pointer',
});

function TopNotificationItem({
  noticeCategory,
  title,
  isNew,
  createdDate,
  noticeId,
}: TopNoticeItem) {
  const defaultQueryString = () => convertObjectToQueryString(NOTICE_INQUIRE_INITIAL_STATE);

  return (
    <StyledNoticeItemLink
      to={{ pathname: `/notice/list/${noticeId}`, search: defaultQueryString() }}
    >
      <Flex align='center' css={{ columnGap: '.4rem' }}>
        <Typo
          css={{
            fontSize: '1.4rem',
            color: '#757C89',
            fontWeight: 400,
            width: 'fit-content',
            maxWidth: '32rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          [{noticeCategory}] {title}
        </Typo>
        {isNew && <NewBadge />}
      </Flex>

      <Typo css={{ fontSize: '1.2rem', color: '#9CA1AA', fontWeight: 400 }}>{createdDate}</Typo>
    </StyledNoticeItemLink>
  );
}

export default TopNotificationItem;
