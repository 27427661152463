Object.groupBy =
  Object.groupBy ||
  function groupBy<T, K>(items: Iterable<T>, callback: (item: T, index: number) => K) {
    const obj = Object.create(null);

    let i = 0;

    for (const value of items) {
      // eslint-disable-next-line no-plusplus
      const key = callback(value, i++) as keyof K;

      key in obj ? obj[key].push(value) : (obj[key] = [value]);
    }

    return obj;
  };
