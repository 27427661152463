import { useMemo, useState } from 'react';

import { useAtomValue } from 'jotai';

import { Board, BoardTitle } from '@dashboard/modules/components/Board';
import { Close } from '@icons/svg';
import * as Notification from '@layouts/Notification';
import * as Popover from '@radix-ui/react-popover';
import { alarmAtom, alarmCountAtom } from '@store/atoms/alarm';

function AlarmContent({ onClose }: { onClose: () => void }) {
  const alarm = useAtomValue(alarmAtom);
  const alarmCount = useAtomValue(alarmCountAtom);

  const [tab, setTab] = useState('');
  const [readOnly, setReadOnly] = useState(true);

  const onChangeTab = (tabValue: string) => setTab(tabValue);

  const onChangeReadOnly = (readOnlyValue: boolean) => setReadOnly(readOnlyValue);

  const alarmList = useMemo(() => {
    if (!readOnly) return alarm;

    return alarm.filter((item) => !item.viewed);
  }, [alarm, readOnly]);

  return (
    <Popover.Portal>
      <Popover.Content
        sideOffset={18}
        alignOffset={-400}
        align='start'
        side='bottom'
        className='PopoverContent'
      >
        <Board
          css={{
            background: 'var(--color-white)',
            width: '50rem',
            borderRadius: '.5rem',
            boxShadow: '0 .5rem 1rem 0 rgba(0, 0, 0, 0.25)',
            padding: '2.4rem',
          }}
        >
          <BoardTitle
            title='알림'
            alarmCount={alarmCount}
            rightNode={
              <Popover.Close>
                <Close />
              </Popover.Close>
            }
            css={{ pb: '2rem' }}
          />
          <Notification.Wrapper
            tab={tab}
            readOnly={readOnly}
            setTab={onChangeTab}
            setReadOnly={onChangeReadOnly}
          >
            <Notification.Tab rightNode={<Notification.ReadTooltip />} />
            <Notification.List
              renderElement={(item) => (
                <Notification.Item
                  key={`dashboard-${item.notificationId}`}
                  {...item}
                  onClick={onClose}
                />
              )}
              emptyElement={
                <Notification.Empty
                  contentCss={{ mt: '2rem', width: '45rem', height: '43rem' }}
                  textCss={{
                    color: '#9CA1AA',
                    fontSize: '1.6rem',
                    fontWeight: 500,
                  }}
                />
              }
              list={alarmList}
              filterKey='notificationCategory'
            />
          </Notification.Wrapper>
        </Board>
      </Popover.Content>
    </Popover.Portal>
  );
}

export default AlarmContent;
