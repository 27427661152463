import axios from 'axios';

import type { LoginParams, PostLoginResponse } from './type';

export const postVendorLogin = async (params: LoginParams) => {
  const { data } = await axios.post<PostLoginResponse>(
    `${import.meta.env.VITE_BASE_AUTH_URL}/auth/partner-login`,
    params
  );
  return data;
};

export const postAdminLogin = async (params: { firebaseUid: string; originDomain: 'SELLER' }) => {
  const { data } = await axios.post<PostLoginResponse>(
    `${import.meta.env.VITE_BASE_AUTH_URL}/auth/admin-login`,
    params
  );
  return data;
};

export const postIntegratedRefresh = async (params: {
  refreshToken: string;
  originDomain: 'SELLER';
}) => {
  const { data } = await axios.post<{ accessToken: string }>(
    `${import.meta.env.VITE_BASE_AUTH_URL}/auth/refresh`,
    params
  );

  return data;
};
