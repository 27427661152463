import { Flex, styled } from '@teampetfriends/react-ui';

const StyledTabItem = styled(Flex, {
  borderRadius: '.5rem',
  border: '.1rem solid rgba(0, 0, 0, 0.10)',
  padding: '.4rem .8rem',
  cursor: 'pointer',
  defaultVariants: {
    status: 'disActive',
  },
  variants: {
    status: {
      active: {
        border: '.1rem solid #FF4081',
        background: 'rgba(255, 64, 129, 0.05)',
        color: '#FF4081',
        fontWeight: 600,
      },
      disActive: {
        border: '.1rem solid rgba(0, 0, 0, 0.10)',
        background: '#fff',
        color: '#2D3035',
        fontWeight: 400,
      },
    },
  },
});

interface NotificationTabItemProps {
  label: string;
  active: boolean;
  onClick: () => void;
}

function NotificationTabItem({ label, active, onClick }: NotificationTabItemProps) {
  return (
    <StyledTabItem
      as='li'
      onClick={onClick}
      align='center'
      justify='center'
      status={active ? 'active' : 'disActive'}
    >
      {label}
    </StyledTabItem>
  );
}

export default NotificationTabItem;
