import { getCalcDate } from '@utils/util';

import type { ProductRequestListParams } from './interfaces';

export const PRODUCT_REQUEST_CHECKBOX_LIST = [
  { id: '', active: true, labelText: '전체' },
  { id: 'REGISTER', active: false, labelText: '상품승인' },
  { id: 'MODIFY', active: false, labelText: '상품정보 변경' },
  { id: 'PRICE', active: false, labelText: '판매가격변경' },
  { id: 'STATUS', active: false, labelText: '판매상태변경' },
  { id: 'CONNECTION', active: false, labelText: '상품연결' },
];

export const PRODUCT_REQUEST_APPROVE_CHECKBOX_LIST = [
  { id: '', active: true, labelText: '전체' },
  { id: 'SAVED', active: false, labelText: '임시저장' },
  { id: 'REQUESTED', active: false, labelText: '승인대기' },
  { id: 'CONFIRMED', active: false, labelText: '승인완료' },
  { id: 'REJECTED', active: false, labelText: '승인반려' },
  { id: 'CANCELED', active: false, labelText: '요청취소' },
];

export const PRODUCT_REQUEST_INITIAL_STATE: ProductRequestListParams = {
  startDate: getCalcDate(new Date(), 1, 'month'),
  endDate: new Date(),
  searchCondition: 'VENDOR_NAME',
  productStockType: '',
  searchContents: '',
  actions: null,
  statuses: null,
  pageNumber: 1,
  pageSize: 10,
  productGroup1Id: '',
  productGroup2Id: '',
  productGroup3Id: '',
  productGroup4Id: '',
};

export const PRODUCT_REQUEST_INITIAL_CATEGORY = {
  1: '',
  2: '',
  3: '',
  4: '',
};
