import { createContext, useContext as useReactContext } from 'react';

import { Nullable } from '@teampetfriends/util-types';

const createSalesRankingContext = <T>(contextName: string) => {
  const context = createContext<Nullable<T>>(null);

  const useContext = (consumerName: string) => {
    const ctx = useReactContext(context);

    if (!ctx) {
      throw new Error(`${contextName}안에 ${consumerName}를 사용해야합니다.`);
    }

    return ctx;
  };

  return [context.Provider, useContext] as const;
};

export const [SalesRankingProvider, useSalesRankingContext] = createSalesRankingContext<{
  sortCondition: string;
  onChangeSortCondition: (val: string) => void;
}>('SalesRankingSection');
