import NotificationEmpty from './modules/components/NotificationEmpty';
import NotificationList from './modules/components/NotificationList';
import NotificationListItem from './modules/components/NotificationListItem';
import NotificationReadTooltip from './modules/components/NotificationReadTooltip';
import NotificationTab from './modules/components/NotificationTab';
import NotificationWrapper from './modules/components/NotificationWrapper';

const Wrapper = NotificationWrapper;
const Tab = NotificationTab;
const List = NotificationList;
const Item = NotificationListItem;
const Empty = NotificationEmpty;
const ReadTooltip = NotificationReadTooltip;

export { Empty, Item, List, ReadTooltip, Tab, Wrapper };
