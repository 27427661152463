import { Typo } from '@teampetfriends/react-ui';

function NewBadge() {
  return (
    <Typo
      css={{
        color: 'var(--color-white)',
        background: '#EA306F',
        width: '2.4rem',
        height: '1.2rem',
        lineHeight: '1.2rem',
        borderRadius: '.3rem',
        fontSize: '.8rem',
        textAlign: 'center',
      }}
    >
      NEW
    </Typo>
  );
}

export default NewBadge;
