import { ReactNode, useState } from 'react';

import { Box, Divider, Flex } from '@teampetfriends/react-ui';

import { useRefetchNotificationListQueries } from '@dashboard/modules/components/AlarmSection/modules/apis';
import { Board } from '@dashboard/modules/components/Board';
import { CheckSmall, MoreVert } from '@icons/svg';
import * as Popover from '@radix-ui/react-popover';

import { useNotificationAllRead } from '../apis';
import { useNotificationContext } from '../context/createNotificationContext';

function NotificationReadTooltipTrigger() {
  return (
    <Popover.Trigger>
      <Box>
        <MoreVert />
      </Box>
    </Popover.Trigger>
  );
}

interface ActiveButtonProps {
  isActive: boolean;
  children: ReactNode;
  onClick: () => void;
}

function ActiveButton({ isActive, children, onClick }: ActiveButtonProps) {
  return (
    <Flex
      as='button'
      type='button'
      align='center'
      onClick={onClick}
      css={{
        paddingLeft: !isActive ? '2.5rem' : 0,
        color: isActive ? '#FF4081' : 'inherit',
        fontWeight: isActive ? 700 : 500,
        height: '2.4rem',
      }}
    >
      {isActive && <CheckSmall />}
      {children}
    </Flex>
  );
}

function NotificationReadTooltipContent({ onClose }: { onClose: () => void }) {
  const { readOnly, setReadOnly } = useNotificationContext('NotificationReadTooltipContent');

  const { refetch } = useRefetchNotificationListQueries();

  const { mutate: allReadMutate } = useNotificationAllRead({
    onSuccess: () => {
      refetch();
    },
  });

  const onClickAllRead = () => {
    allReadMutate();
    onClose();
  };

  const onClickFilterRead = (isRead: boolean) => {
    setReadOnly(isRead);
    onClose();
  };

  return (
    <Popover.Portal>
      <Popover.Content sideOffset={5} alignOffset={-80} align='start' side='bottom'>
        <Board css={{ background: '#fff', padding: '1.4rem 1rem', rowGap: '1.3rem' }}>
          <Flex direction='column' css={{ rowGap: '.6rem' }}>
            <ActiveButton isActive={readOnly === true} onClick={() => onClickFilterRead(true)}>
              읽지 않은 알림
            </ActiveButton>
            <ActiveButton isActive={readOnly === false} onClick={() => onClickFilterRead(false)}>
              전체 알림 보기
            </ActiveButton>
          </Flex>

          <Divider css={{ backgroundColor: '#DBDDE0' }} />

          <Flex
            as='button'
            type='button'
            justify='end'
            css={{ width: '100%' }}
            onClick={onClickAllRead}
          >
            모두 읽음 처리
          </Flex>
        </Board>
      </Popover.Content>
    </Popover.Portal>
  );
}

function NotificationReadTooltip() {
  const [open, setOpen] = useState(false);

  const closeTooltip = () => {
    setOpen(false);
  };

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <NotificationReadTooltipTrigger />
      <NotificationReadTooltipContent onClose={closeTooltip} />
    </Popover.Root>
  );
}

export default NotificationReadTooltip;
