import { ReactNode } from 'react';

import { CSS, Flex } from '@teampetfriends/react-ui';

interface BoardProps {
  css?: CSS;
  children: ReactNode;
}

export function Board({ css, children }: BoardProps) {
  return (
    <Flex direction='column' css={{ p: '4rem', border: '.1rem solid #DBDDE0', ...css }}>
      {children}
    </Flex>
  );
}
