import { DELIVERY_MANAGE_INITIAL_STATE } from '@delivery/DeliveryManage/modules/constant';
import { getSettlementInitialState } from '@settlement/commons/modules/constant';

export const getSettlementRouteTableWithParameters = (notificationType: string) => {
  let path = '';
  let params = {};

  switch (notificationType) {
    case 'SETTLEMENT_VENDOR_CLOSE':
      path = '/vendor-settlement/list';
      params = getSettlementInitialState();
      break;
    case 'SETTLEMENT_CONSIGNMENT_CLOSE':
      path = '/consignment-settlement/list';
      params = getSettlementInitialState();
      break;
    case 'SETTLEMENT_COUPON_CLOSE':
      path = '/coupon-settlement/list';
      break;
    default:
      path = '';
      break;
  }

  return { path, params };
};

export const getDeliveryRouteTableWithParameters = (notificationType: string) => {
  let path = '';
  let params = {};

  switch (notificationType) {
    case 'DELIVERY_PREPARATION_DELAYED':
      path = '/delivery/manage';
      params = { ...DELIVERY_MANAGE_INITIAL_STATE };
      break;
    case 'DELIVERY_COMPLETION_DELAYED':
      path = '/delivery/manage';
      params = { ...DELIVERY_MANAGE_INITIAL_STATE, endPointKey: 21, delayedDelivery: 'Y' };
      break;
    default:
      path = '/delivery/manage';
      params = { ...DELIVERY_MANAGE_INITIAL_STATE };
      break;
  }

  return { path, params };
};
