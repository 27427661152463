import { type ChangeEvent } from 'react';

import { Flex, Typo } from '@teampetfriends/react-ui';

import { Select } from '@components/Select';
import useOptions from '@hooks/useOptions';
import { getTokenData } from '@utils/token';

import { useSalesRankingContext } from './context/createSalesRankingContext';
import { SortAdminTooltip, SortVendorTooltip } from './Tooltips';

function SortSelect() {
  const checkUser = getTokenData();

  const { sortCondition, onChangeSortCondition: onChange } = useSalesRankingContext('SortSelect');

  const [productSalesRankingSortCondition] = useOptions(['productSalesRankingSortCondition']);

  const onChangeSortCondition = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  return (
    <Flex align='center' css={{ columnGap: '1rem' }}>
      <Flex align='center' css={{ columnGap: '.5rem' }}>
        <Typo css={{ fontSize: '1.6rem', color: '#757C89', fontWeight: 700 }}>순위 기준</Typo>
        {checkUser?.is_vendor ? <SortVendorTooltip /> : <SortAdminTooltip />}
      </Flex>
      <Select
        value={sortCondition}
        onChange={onChangeSortCondition}
        name='sortCondition'
        width='w-[20rem]'
      >
        {productSalesRankingSortCondition?.map(({ key, value }) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </Select>
    </Flex>
  );
}

export default SortSelect;
