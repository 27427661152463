import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useSetAtom } from 'jotai';

import { type Alarm, alarmAtom, alarmCountAtom } from '@store/atoms/alarm';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { client } from '@utils/client';
import { getListQueryStringMaker } from '@utils/util';

import { useAlarmStreaming } from '../hooks/useAlarmStreaming';

export const getNotificationList = async () => {
  const url = getListQueryStringMaker('/portals/notifications', {
    startDate: null,
    endDate: null,
    unreadOnly: 'false',
  });

  const { data } = await client(false).get<{ unreadCount: number; notifications: Alarm[] }>(url);

  return data;
};

export const useRefetchNotificationListQueries = () => {
  const queryClient = useQueryClient();

  const refetch = () => {
    queryClient.invalidateQueries(['dashboard', 'list']);
  };

  return { refetch };
};

export const useNotificationList = () => {
  const search = useLocation();

  const setAlarm = useSetAtom(alarmAtom);
  const setAlarmCount = useSetAtom(alarmCountAtom);

  useAlarmStreaming({ enabled: true });

  const { refetch } = useQuery({
    queryKey: ['dashboard', 'list'],
    queryFn: getNotificationList,
    keepPreviousData: true,
    onSuccess: (data) => {
      setAlarmCount(data.unreadCount);
      setAlarm(data.notifications);
    },
  });

  useEffect(() => {
    if (search.pathname.includes('dashboard')) refetch();
  }, [search.pathname]);
};
