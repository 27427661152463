import { Flex } from '@teampetfriends/react-ui';

import type { SettlementItemProps } from '@dashboard/modules/interfaces';

import * as WhiteItem from '../../../WhiteItem';

function AdminSettlementItem({ title, value, unit, onClick }: SettlementItemProps) {
  return (
    <WhiteItem.Wrapper
      css={{
        flex: 1,
        flexDirection: 'column',
        height: '17rem',
        padding: '2.5rem 2rem',
        justifyContent: 'space-between',
        alignSelf: 'stretch',
      }}
      onClick={onClick}
    >
      <Flex justify='between'>
        <WhiteItem.Head
          title={title}
          css={{
            whiteSpace: 'pre-wrap',
          }}
        />
        <WhiteItem.Arrow />
      </Flex>
      <WhiteItem.Content value={value} unit={unit} />
    </WhiteItem.Wrapper>
  );
}

export default AdminSettlementItem;
