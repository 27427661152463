import { getCalcDate } from '@utils/util';

import type { CouponParams } from './interfaces';

export const COUPON_REQUEST_INITIAL_STATE: CouponParams = {
  dateCondition: 'COUPON_REQUEST_CREATED_DATE',
  startDate: getCalcDate(new Date(), 1, 'month'),
  endDate: new Date(),
  requestStatuses: null,
  couponStatuses: null,
  searchCondition: 'COUPON_NAME',
  searchContents: '',
  pageNumber: 1,
  pageSize: 10,
};

export const ONlY_NUMBER_STATUS = ['COUPON_CODE', 'SELLER_COUPON_ID', 'PRODUCT_ID', 'VENDOR_ID'];
