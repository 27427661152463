import { Flex, Typo } from '@teampetfriends/react-ui';

import Button from '@components/Button';
import { useGetNoticeListWithDashboard } from '@dashboard/modules/apis';
import { useRoute } from '@hooks/useRoute';
import { NOTICE_INQUIRE_INITIAL_STATE } from '@notice/NoticeInquire/modules/constant';

import { Board, BoardTitle } from '../Board';

import NoticeItem from './modules/components/NoticeItem';

function NoticeSection() {
  const { push } = useRoute();

  const { noticeList } = useGetNoticeListWithDashboard();

  const onClickNavigateNoticeInquire = () => {
    push('/notice/list', NOTICE_INQUIRE_INITIAL_STATE);
  };

  return (
    <Board css={{ background: '#F9F9FA', height: '100%' }}>
      <BoardTitle
        title='공지사항'
        css={{ pb: '2rem' }}
        rightNode={
          <Button color='white' onClick={onClickNavigateNoticeInquire} type='button'>
            전체보기
          </Button>
        }
      />
      {!noticeList?.length ? (
        <Flex css={{ height: '100%', mt: '2rem' }} align='center' justify='center'>
          <Typo
            css={{
              color: '#9CA1AA',
              fontSize: '1.6rem',
              fontWeight: 500,
            }}
          >
            공지사항이 없습니다.
          </Typo>
        </Flex>
      ) : (
        <Flex as='ul' direction='column' css={{ height: '100%', mt: '2rem', rowGap: '1.6rem' }}>
          {noticeList?.map((notice) => (
            <NoticeItem {...notice} key={notice.noticeId} />
          ))}
        </Flex>
      )}
    </Board>
  );
}

export default NoticeSection;
