import { useMemo, useSyncExternalStore } from 'react';

import {
  getTokens,
  removeTokens,
  setTokens,
  TARGET_LOCAL_STORAGE_TOKEN,
  TokenParams,
  TokenSetParams,
} from '@utils/token';

const authStoreInstance = () => {
  const subscribe = (listener: () => void) => {
    window.addEventListener('storage', listener);
    return () => window.removeEventListener('storage', listener);
  };

  const get = (tokens: TokenParams) => {
    return { storage: localStorage.getItem(TARGET_LOCAL_STORAGE_TOKEN), cookie: getTokens(tokens) };
  };

  const update = (tokens: TokenSetParams) => {
    setTokens(tokens);
    localStorage.setItem(TARGET_LOCAL_STORAGE_TOKEN, tokens?.accessToken ?? '');

    window.dispatchEvent(
      new StorageEvent('storage', { key: TARGET_LOCAL_STORAGE_TOKEN, newValue: tokens.accessToken })
    );
  };

  const remove = () => {
    removeTokens();
    localStorage.removeItem(TARGET_LOCAL_STORAGE_TOKEN);

    window.dispatchEvent(
      new StorageEvent('storage', { key: TARGET_LOCAL_STORAGE_TOKEN, newValue: null })
    );
  };

  return {
    subscribe,
    get,
    update,
    remove,
  };
};

export const authStore = authStoreInstance();

export const useAuthInfo = (tokenName: TokenParams) => {
  const [selector, update, remove] = useMemo(
    () => [
      () => authStore.get(tokenName)?.storage,
      (value: TokenSetParams) => authStore.update(value),
      () => authStore.remove(),
    ],
    [JSON.stringify(tokenName)]
  );

  const value = useSyncExternalStore(authStore.subscribe, selector);

  return {
    storage: value,
    cookie: authStore.get(tokenName)?.cookie,
    update,
    remove,
  };
};

export const useAuthInfoAction = () => {
  const [update, remove] = useMemo(
    () => [(value: TokenSetParams) => authStore.update(value), () => authStore.remove()],
    []
  );

  return { update, remove };
};
