import { createContext, useContext as useReactContext } from 'react';

import type { Nullable } from '@teampetfriends/util-types';

import type { NotificationContext } from '../interfaces';

const createNotificationContext = <T>(contextName: string) => {
  const context = createContext<Nullable<T>>(null);

  const useContext = (consumerName: string) => {
    const ctx = useReactContext(context);

    if (!ctx) {
      throw new Error(`${contextName}안에 ${consumerName}를 사용해야합니다.`);
    }

    return ctx;
  };

  return [context.Provider, useContext] as const;
};

export const [NotificationProvider, useNotificationContext] =
  createNotificationContext<NotificationContext>('Notification');
