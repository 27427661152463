import { SizeKey } from './hooks/useModal';

export const MODAL_STYLE: { [key in SizeKey]: string } = {
  alert: 'h-fit w-[60rem] py-[3rem] px-[3rem] gap-y-[1.2rem]',
  m: 'w-[51rem] h-auto max-h-[90vh] py-[3rem] px-[3rem]',
  m2: 'w-[72rem] h-auto max-h-[90vh] py-[3rem] px-[3rem]',
  m3: 'w-[100rem] h-auto max-h-[90vh] py-[3rem] px-[3rem]',
  l: 'w-[120rem] h-auto max-h-[90vh] py-[3rem] px-[3rem]',
  preview: 'min-w-[38rem] h-auto max-h-[90vh] py-[3rem] px-[3rem]',
  preview2: 'min-w-[65.3rem] h-auto max-h-[90vh] py-[3rem] px-[3rem]',
};

export const NO_CLOSE_BUTTON_SIZES = ['alert', 'preview2'];

export const DEFAULT_SIZE = 'm';
