import { Cookie, jwtDecode } from '@teampetfriends/utils';

import { queryClient } from 'src';

import { postIntegratedRefresh } from '@auth/Login/apis';
import { authStore } from '@hooks/useSyncAuth';

export const TARGET_ACCESS_TOKEN = 'partner_tk';
export const TARGET_REFRESH_TOKEN = 'partner_rtk';
export const TARGET_LOCAL_STORAGE_TOKEN = 'partner_user_info';

export type TokenInfo = {
  user_id: number;
  user_name: string;
  user_account: string;
  password_reset: boolean;
  is_vendor: boolean;
  // PURCHASE 매입 / VENDOR 업체배송 / CONSIGNMENT 위탁배송
  allowed_types: ('VENDOR' | 'CONSIGNMENT' | 'PURCHASE')[];
  origin_domain: 'SELLER' | 'SUPPLY';
  exp: number;
};

type TokenName = 'accessToken' | 'refreshToken';
export type TokenParams = [TokenName] | [TokenName, TokenName];
export type TokenSetParams = { [key in TokenName]?: string };

const TOKENS: Record<TokenName, string> = {
  accessToken: `${TARGET_ACCESS_TOKEN}-${import.meta.env.VITE_ENV}`,
  refreshToken: `${TARGET_REFRESH_TOKEN}-${import.meta.env.VITE_ENV}`,
};

export const getTokens = (tokens: TokenParams) => {
  return tokens.map((token) => {
    const target = TOKENS[token];
    const currentCookie = Cookie.getCookie(target);

    if (!currentCookie) return null;
    return currentCookie;
  });
};

export const setTokens = (tokens: TokenSetParams) => {
  const isLocalhost = window.location.host.includes('localhost');
  const domain = isLocalhost ? 'localhost' : '.pet-friends.co.kr';

  Object.entries(tokens).forEach(([key, value]) => {
    const tokenKey = TOKENS[key as TokenName];

    if (value) Cookie.setCookie(tokenKey, value, { domain });
  });
};

export const removeTokens = () => {
  const isLocalhost = window.location.host.includes('localhost');
  const domain = isLocalhost ? 'localhost' : '.pet-friends.co.kr';

  Object.values(TOKENS).forEach((key) => {
    Cookie.removeCookie(key, { domain });
  });
};

export const getTokenData = () => {
  try {
    const [currentCookie] = getTokens(['accessToken']);

    if (!currentCookie) return null;

    return jwtDecode<TokenInfo>(currentCookie);
  } catch (err) {
    removeTokens();
    return null;
  }
};

export const redirectToLoginPage = () => {
  window.location.href = '/login';
  return false;
};

export type RefreshTokenSettingResponse = { accessToken: string };

export const refreshTokenSetting = async (): Promise<RefreshTokenSettingResponse> => {
  const [accessToken, refreshToken] = getTokens(['accessToken', 'refreshToken']);

  if (!accessToken) throw new Error('인증토큰 없음.');

  if (!refreshToken) throw new Error('리프레쉬 토큰이 유실됨.');

  const token = await postIntegratedRefresh({ refreshToken, originDomain: 'SELLER' });

  return token;
};

export const useRefreshTokenSetting = async () => {
  try {
    const tokens = await queryClient?.fetchQuery(['refresh'], refreshTokenSetting);
    return tokens;
  } catch (err) {
    authStore.remove();
    redirectToLoginPage();
  }
};
