import { Box, Flex, Typo } from '@teampetfriends/react-ui';

import { useRefetchNotificationListQueries } from '@dashboard/modules/components/AlarmSection/modules/apis';
import { useDebounce } from '@hooks/useDebounce';
import { useRoute } from '@hooks/useRoute';
import { type Alarm } from '@store/atoms/alarm';
import { convertQueryStringToObject } from '@utils/queryString';

import { useNotificationRead } from '../apis';
import { ALARM_ROUTE_TABLE_WITH_PARAMETERS } from '../constant';
import {
  getDeliveryRouteTableWithParameters,
  getSettlementRouteTableWithParameters,
} from '../utils';

interface NotificationListItemProps extends Alarm {
  onClick?: (item?: Alarm) => void;
}

function NotificationListItem({ onClick, ...rest }: NotificationListItemProps) {
  const {
    title,
    notificationDate,
    content,
    queryString,
    notificationId,
    viewed,
    notificationCategory,
    notificationType,
  } = rest;

  const { push } = useRoute();

  const { refetch } = useRefetchNotificationListQueries();

  const pushWithParameter = () => {
    const newParams = convertQueryStringToObject(queryString) as object;

    if (notificationCategory === 'SETTLEMENT') {
      const { path, params } = getSettlementRouteTableWithParameters(notificationType);
      push(path, { ...params, ...newParams });
    } else if (notificationCategory === 'DELIVERY') {
      const { path, params } = getDeliveryRouteTableWithParameters(notificationType);
      push(path, { ...params, ...newParams });
    } else {
      const { path, params } =
        ALARM_ROUTE_TABLE_WITH_PARAMETERS[
          notificationCategory as keyof typeof ALARM_ROUTE_TABLE_WITH_PARAMETERS
        ];

      push(path, { ...params, ...newParams });
    }
  };

  const { mutate: readMutate } = useNotificationRead({
    onSuccess: () => {
      refetch();
      pushWithParameter();
    },
  });

  const onClickNotification = useDebounce(() => {
    onClick?.(rest);

    if (!viewed) {
      readMutate({ notificationId });
      return;
    }

    pushWithParameter();
  }, 300);

  return (
    <Flex
      as='li'
      direction='column'
      css={{
        width: '100%',
        border: '.1rem solid #E5E5E5',
        borderRadius: '1rem',
        padding: '1.8rem 1.5rem',
        background: 'var(--color-white)',
        rowGap: '1.5rem',
        cursor: 'pointer',
      }}
      onClick={onClickNotification}
    >
      <Flex align='center' justify='between' css={{ width: '100%' }}>
        <Flex align='start'>
          <Typo css={{ fontSize: '1.6rem', fontWeight: 600 }}>{title}</Typo>
          {!viewed && (
            <Box
              css={{ background: '#FF4081', width: '.6rem', height: '.6rem', borderRadius: '50%' }}
            />
          )}
        </Flex>
        <Typo css={{ fontWeight: 400 }}>{notificationDate}</Typo>
      </Flex>
      <Typo
        css={{
          fontSize: '1.6rem',
          fontWeight: 500,
          whiteSpace: 'pre-wrap',
          lineHeight: '$148p',
        }}
      >
        {content}
      </Typo>
    </Flex>
  );
}

export default NotificationListItem;
