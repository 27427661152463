import { useLocation, useNavigate } from 'react-router-dom';

import { convertObjectToQueryString } from '@utils/queryString';

export const useRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const replace = <T extends Record<string, any>>(params: T) => {
    const queryString = convertObjectToQueryString(params);

    navigate(
      {
        pathname: location.pathname,
        search: queryString,
      },
      {
        replace: true,
      }
    );
  };

  const push = <T extends Record<string, any>>(pathname: string, params: T) => {
    const queryString = convertObjectToQueryString(params);

    navigate({
      pathname,
      search: queryString,
    });
  };

  return { replace, push };
};
