import { CSS, Flex, Typo } from '@teampetfriends/react-ui';

interface NotificationEmptyProps {
  contentCss?: CSS;
  textCss?: CSS;
}

function NotificationEmpty({ contentCss, textCss }: NotificationEmptyProps) {
  return (
    <Flex direction='column' align='center' justify='center' css={contentCss}>
      <Typo css={textCss}>새로운 알림이 없습니다.</Typo>
    </Flex>
  );
}

export default NotificationEmpty;
