import { Flex, Typo } from '@teampetfriends/react-ui';

function NewBadge() {
  return (
    <Flex
      align='center'
      justify='center'
      css={{ borderRadius: '50%', background: '#FF4081', width: '1.5rem', height: '1.5rem' }}
    >
      <Typo css={{ color: '#fff', fontWeight: 700, fontSize: '.9rem', mt: '.1rem' }}>N</Typo>
    </Flex>
  );
}

export default NewBadge;
