import { useMemo, useState } from 'react';

import { useAtomValue } from 'jotai';

import * as Notification from '@layouts/Notification';
import { alarmAtom, alarmCountAtom } from '@store/atoms/alarm';

import { Board, BoardTitle } from '../Board';

function AlarmSection() {
  const alarm = useAtomValue(alarmAtom);
  const alarmCount = useAtomValue(alarmCountAtom);

  const [tab, setTab] = useState('');
  const [readOnly, setReadOnly] = useState(true);

  const onChangeTab = (tabValue: string) => setTab(tabValue);

  const onChangeReadOnly = (readOnlyValue: boolean) => setReadOnly(readOnlyValue);

  const alarmList = useMemo(() => {
    if (!readOnly) return alarm;

    return alarm.filter((item) => !item.viewed);
  }, [alarm, readOnly]);

  return (
    <Board css={{ background: '#F9F9FA', flex: 1, height: '63.2rem' }}>
      <BoardTitle
        title='알림'
        alarmCount={alarmCount}
        description='최근 2주일 기준'
        css={{ pb: '2rem' }}
      />
      <Notification.Wrapper
        tab={tab}
        readOnly={readOnly}
        setTab={onChangeTab}
        setReadOnly={onChangeReadOnly}
      >
        <Notification.Tab rightNode={<Notification.ReadTooltip />} />
        <Notification.List
          renderElement={(item) => (
            <Notification.Item key={`dashboard-${item.notificationId}`} {...item} />
          )}
          emptyElement={
            <Notification.Empty
              contentCss={{
                mt: '2rem',
                height: '100%',
                background: '#F9F9FA',
                width: '100%',
              }}
              textCss={{
                color: '#9CA1AA',
                fontSize: '1.6rem',
                fontWeight: 500,
              }}
            />
          }
          list={alarmList}
          filterKey='notificationCategory'
        />
      </Notification.Wrapper>
    </Board>
  );
}

export default AlarmSection;
