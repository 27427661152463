import { useEffect, useRef } from 'react';

import type { Nullable } from '@teampetfriends/util-types';

import {
  type Event,
  type EventSourcePolyfillInit,
  EventSourcePolyfill,
} from 'event-source-polyfill';

export interface UseSSEStreamingParams<T> extends EventSourcePolyfillInit {
  url: string;
  enabled?: boolean;
  onOpen?: (event: Event) => void;
  onError?: (event: Event) => void;
  onMessage?: (data: { data: T; lastEventId: string }) => void;
}

const IGNORE_MESSAGES = ['EventStream Created.', 'Heartbeat'];

export const useSSEStreaming = <T>({
  url,
  enabled = true,
  onOpen,
  onError,
  onMessage,
  ...options
}: UseSSEStreamingParams<T>) => {
  const eventSource = useRef<Nullable<EventSource>>(null);

  const disconnect = () => {
    eventSource?.current?.close();
    eventSource.current = null;
  };

  const connect = async () => {
    if (!enabled || eventSource.current) return;

    const listener = new EventSourcePolyfill(url, { ...options });

    eventSource.current = listener;

    if (onOpen) listener.onopen = onOpen;

    if (onError) listener.onerror = onError;

    if (onMessage)
      listener.onmessage = (event) => {
        if (
          typeof event.data === 'string' &&
          IGNORE_MESSAGES.some((message) => event.data.includes(message))
        )
          return false;

        onMessage({
          ...event,
          data: JSON.parse(event.data),
        });
      };
  };

  useEffect(() => {
    connect();
    return () => {
      disconnect();
    };
  }, []);
};
