import { deleteUser, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';

import { toastify } from '@components/Toast';

import { postAdminLogin } from '../apis';

import { auth } from './config';

export const logout = async () => signOut(auth);

export const adminLogin = async () => {
  const signIn = await signInWithPopup(auth, new GoogleAuthProvider());
  const firebaseUid = signIn?.user?.uid;

  try {
    const { accessToken, refreshToken } = await postAdminLogin({
      firebaseUid,
      originDomain: 'SELLER',
    });

    return { refreshToken, accessToken };
  } catch (err) {
    toastify('가입된 계정이 아닙니다.', { type: 'error' });
    logout();
    deleteUser(signIn?.user);
    return false;
  }
};
