import { Link, useLocation } from 'react-router-dom';

import { styled, Typo } from '@teampetfriends/react-ui';

import { convertObjectToQueryString } from '../../../../utils/queryString';
import NewBadge from '../NewBadge';

interface MenuLinkProps {
  name: string;
  path: string;
  isNew: boolean;
  params?: Record<string, unknown>;
}

const StyledLink = styled(Link, {
  padding: '1.2rem 2.4rem',
  display: 'flex',
  alignItems: 'center',
  borderTop: '.2rem solid #E9EBEC',
  borderBottom: '.3rem solid #E9EBEC',
  columnGap: '.6rem',
});

function MenuLink({ name, isNew, path, params }: MenuLinkProps) {
  const { pathname } = useLocation();

  const queryString = convertObjectToQueryString(params || {});

  return (
    <StyledLink
      to={{
        pathname: path,
        search: queryString,
      }}
    >
      <Typo
        css={{
          fontSize: '1.4rem',
          color: pathname.includes(path) ? '#FF4081' : '#757c89',
          fontWeight: 400,
        }}
      >
        {name}
      </Typo>
      {isNew && <NewBadge />}
    </StyledLink>
  );
}

export default MenuLink;
