import { ReactNode } from 'react';

import { type CSS, Flex, Typo } from '@teampetfriends/react-ui';

type SizeKey = 's' | 'm';

type ColorKey = 'red' | 'grey';

const SIZE: Record<SizeKey, { box: CSS; text: CSS }> = {
  s: {
    box: {
      padding: '.4rem 1rem',
    },
    text: {
      fontSize: '1.3rem',
    },
  },
  m: {
    box: {
      padding: '.6rem 1.3rem',
    },
    text: {
      fontSize: '1.5rem',
    },
  },
};

const COLOR: Record<ColorKey, { box: CSS; text: CSS }> = {
  red: {
    box: {
      border: '.1rem solid #ff030361',
    },
    text: {
      color: '#FF0101',
    },
  },
  grey: {
    box: {
      border: '.1rem solid #9E9E9E',
    },
    text: {
      color: '#9E9E9E',
    },
  },
};

function Badge({
  children,
  size = 'm',
  color = 'red',
}: {
  children: ReactNode;
  size: SizeKey;
  color: ColorKey;
}) {
  return (
    <Flex
      align='center'
      justify='center'
      css={{
        ...SIZE[size].box,
        ...COLOR[color].box,
        borderRadius: '.5rem',
      }}
    >
      <Typo css={{ ...SIZE[size].text, ...COLOR[color].text, fontWeight: 400 }}>{children}</Typo>
    </Flex>
  );
}

export default Badge;
