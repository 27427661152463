import { type VendorItem } from '@hooks/useVendorList';
import { getTokenData } from '@utils/token';

import type { ConsignmentSummary, ExcelUploadFormState, VendorSummary } from './interfaces';
import { getCurrentMonth } from './utils';

export const INITIAL_TOTAL_VENDOR: VendorItem = { label: '전체', value: Number.MAX_SAFE_INTEGER };

export const getSettlementInitialState = () => {
  const checkUser = getTokenData();

  const yearMonth = getCurrentMonth();

  if (checkUser?.is_vendor) return { yearMonth };

  return {
    yearMonth,
    vendorId: INITIAL_TOTAL_VENDOR.value,
  };
};

export const INITIAL_ORDER_CHARGE = {
  orderCount: 0,
  orderChargeFee: 0,
  orderChargeCancelFee: 0,
  carryOverCancelFee: 0,
  etcCount: 0,
  etcFee: 0,
  totalFee: 0,
};

export const INITIAL_ORDER_CHARGE_CLAIM = {
  claimCount: 0,
  claimProductFee: 0,
  claimDeliveryFee: 0,
  totalFee: 0,
};

export const INITIAL_SETTLEMENT_CLOSE = {
  closed: true,
  closedAt: '',
  closedUserName: '',
};

export const INITIAL_LOGISTICS = {
  shippingFee: 0,
  storageFee: 0,
  etcCount: 0,
  etcFee: 0,
  totalFee: 0,
};

export const DEFAULT_VENDOR_SUMMARY: VendorSummary = {
  settlementClose: INITIAL_SETTLEMENT_CLOSE,
  orderCharge: INITIAL_ORDER_CHARGE,
  orderChargeClaim: INITIAL_ORDER_CHARGE_CLAIM,
};

export const DEFAULT_CONSIGNMENT_SUMMARY: ConsignmentSummary = {
  settlementClose: INITIAL_SETTLEMENT_CLOSE,
  orderCharge: INITIAL_ORDER_CHARGE,
  orderChargeClaim: INITIAL_ORDER_CHARGE_CLAIM,
  logistics: INITIAL_LOGISTICS,
};

export const EXCEL_UPLOAD_WITH_FORM_INITIAL_STATE: ExcelUploadFormState = {
  vendor: INITIAL_TOTAL_VENDOR,
  content: '',
  fee: '',
  fileName: '',
  filePath: '',
  file: null,
};

export const MAX_LENGTH = 20;

export const ALL_TYPE = '.xls,.xlsx,image/jpeg,image/png,application/pdf';

export const EXTENSION = '.xls,.xlsx,.jpeg,.JPEG,.png,.PNG,.pdf';

export const 계좌번호 = '신한은행 140-013-575884 (예금주: 주식회사 펫프렌즈)';
