/* eslint-disable jsx-a11y/tabindex-no-positive */

import { PropsWithChildren } from 'react';

import { Box, Divider, Flex, Typo } from '@teampetfriends/react-ui';

import { WhiteClose } from '@icons/svg';

interface NoticeModalProps extends PropsWithChildren {
  onClose: VoidFunction;
  onDayClose: VoidFunction;
}

function NoticeModal({ children, onClose, onDayClose }: NoticeModalProps) {
  return (
    <Flex
      className='shadow-md'
      direction='column'
      css={{
        position: 'fixed',
        top: '8rem',
        left: '30rem',
        width: 460,
        border: '1px solid rgba(0, 0, 0, 0.47)',
        background: '#fff',
        borderRadius: '.7rem',
        overflow: 'hidden',
        zIndex: 8,
      }}
    >
      <Flex
        css={{ backgroundColor: '#363A3E', px: '2.2rem', height: '4.6rem' }}
        align='center'
        justify='between'
      >
        <Typo css={{ fontSize: '2rem', color: 'var(--color-white)' }}>
          펫프렌즈 판매자 어드민 공지
        </Typo>
        <button type='button' onClick={onClose}>
          <WhiteClose />
        </button>
      </Flex>

      <Flex css={{ flex: 1, padding: '1.2rem 2.2rem 3rem' }} direction='column'>
        {children}
      </Flex>

      <Flex css={{ width: '100%', height: '5rem', borderTop: '.1rem solid #eaeaea' }}>
        <Box as='button' css={{ width: 'calc(50% - .05rem)' }} tabIndex={1} onClick={onDayClose}>
          오늘 하루 보지 않기
        </Box>
        <Divider css={{ height: '100% !important', width: '.1rem !important' }} />
        <Box as='button' css={{ width: 'calc(50% - .05rem)' }} tabIndex={2} onClick={onClose}>
          닫기
        </Box>
      </Flex>
    </Flex>
  );
}

export default NoticeModal;
