import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { getTokenData } from '@utils/token';

function MainRedirect() {
  const user = getTokenData();

  const url = useMemo(() => {
    // NOTE: 어찌 될지 몰라 주석처리해두었습니다.
    // const allowedType = user?.allowed_types;

    // if (allowedType?.includes('VENDOR')) {
    //   return '/dashboard';
    // }

    // if (allowedType?.includes('CONSIGNMENT')) {
    //   return '/outbound/list';
    // }

    // if (allowedType?.includes('PURCHASE')) {
    //   // return '/coupon/request';
    //   return '/delivery/manage';
    // }

    return '/dashboard';
  }, []);

  return <Navigate to={url} />;
}

export default MainRedirect;
