import { useEffect, useMemo } from 'react';

import debounce from 'lodash.debounce';

import type { Callback } from './type';
import { usePreservedCallback } from './usePreserveCallback';

export const useDebounce = (callback: Callback, timer: number) => {
  const preservedCallback = usePreservedCallback(callback);

  const debounced = useMemo(() => {
    return debounce(preservedCallback, timer);
  }, [preservedCallback, timer]);

  useEffect(() => {
    return () => {
      debounced.cancel();
    };
  }, [debounced]);

  return debounced;
};
