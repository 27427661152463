import { ReactNode } from 'react';

import { Flex, styled } from '@teampetfriends/react-ui';

import { BANNER_IMG_URL } from '@auth/Login/constant';

const Section = styled(Flex, {
  width: '100vw',
  height: '100vh',
  background: 'var(--color-grey-30)',
  position: 'relative',
});

function BackgroundLayout({
  children,
  adminLogin,
}: {
  children: ReactNode;
  adminLogin?: ReactNode;
}) {
  return (
    <Section as='section' align='center' justify='center'>
      {adminLogin && adminLogin}
      <div className='w-[46rem]'>
        {children}
        <div className='mt-[3rem] w-[46rem] h-[9rem] bg-grey-500 rounded-[1rem]'>
          <img src={BANNER_IMG_URL} alt='문제가 생기셨나요?' className='w-full' />
        </div>
      </div>
    </Section>
  );
}

export default BackgroundLayout;
