import { ReactNode } from 'react';

import { CSS, Flex, Typo } from '@teampetfriends/react-ui';

import { BoardBadge } from './Badge';

interface BoardTitleProps {
  title: string;
  description?: string;
  alarmCount?: number;
  rightNode?: ReactNode;
  css?: CSS;
}

export function BoardTitle({ title, description, alarmCount, rightNode, css }: BoardTitleProps) {
  return (
    <Flex
      align='center'
      css={{ columnGap: '.8rem', borderBottom: '.1rem solid #A5A6A8', ...css }}
      justify='between'
    >
      <Flex align='center' css={{ columnGap: '.8rem' }}>
        <Flex align='center' css={{ columnGap: '.4rem' }}>
          <Typo as='h4' css={{ fontSize: '2.4rem', fontWeight: 700 }}>
            {title}
          </Typo>
          {alarmCount !== undefined ? <BoardBadge count={alarmCount} /> : null}
        </Flex>
        <Typo as='span' css={{ fontSize: '1.2rem', color: '#A5A6A8', fontWeight: 300 }}>
          {description}
        </Typo>
      </Flex>
      {rightNode}
    </Flex>
  );
}
