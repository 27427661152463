import { NumberAndUnitCell, NumberAndWonCell } from '@components/Cell';
import { columnHelper, getValue, getValueWithNullable } from '@components/Table';
import type { VendorSalesRankingItem } from '@dashboard/modules/interfaces';

import { EllipsisTextWithTooltipCell } from '../Tooltips';

import { SellingPriceHeader } from './Cell';

const vendorSalesRankingColumnHelper = columnHelper<VendorSalesRankingItem>();

export const getVendorSalesRankingColumns = () => {
  return [
    vendorSalesRankingColumnHelper.accessor('rank', {
      header: '순위',
      cell: getValue,
    }),
    vendorSalesRankingColumnHelper.accessor('productId', {
      header: '판매상품코드',
      cell: getValueWithNullable,
    }),
    vendorSalesRankingColumnHelper.accessor('optionCode', {
      header: '옵션코드',
      cell: getValueWithNullable,
    }),
    vendorSalesRankingColumnHelper.accessor('productName', {
      header: '상품명',
      cell: EllipsisTextWithTooltipCell,
    }),
    vendorSalesRankingColumnHelper.accessor('optionName', {
      header: '옵션명',
      cell: EllipsisTextWithTooltipCell,
    }),
    vendorSalesRankingColumnHelper.accessor('optionValue', {
      header: '옵션값',
      cell: EllipsisTextWithTooltipCell,
    }),
    vendorSalesRankingColumnHelper.accessor('orderCount', {
      header: '주문수',
      cell: (data) => <NumberAndUnitCell {...data} type='건' />,
    }),
    vendorSalesRankingColumnHelper.accessor('salesCount', {
      header: '판매수량',
      cell: (data) => <NumberAndUnitCell {...data} type='개' />,
    }),
    vendorSalesRankingColumnHelper.accessor('sellingPrice', {
      header: SellingPriceHeader,
      cell: (data) => <NumberAndWonCell {...data} />,
    }),
  ];
};
