import { atom } from 'jotai';

export type Alarm = {
  content: string;
  notificationCategory: string;
  notificationType: string;
  notificationDate: string;
  notificationId: number;
  queryString: string;
  title: string;
  vendorId: number;
  viewed: boolean;
};

export const alarmAtom = atom<Alarm[]>([]);

export const alarmCountAtom = atom<number>(0);
