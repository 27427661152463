import { useQuery, useQueryClient } from '@tanstack/react-query';
import { client } from '@utils/client';

import type { GetTopNoticeListResponse } from '../interfaces';

const TOP_NOTICE_LIST_QUERY_KEY = ['notice', 'top', 'list'] as const;

export const useGetTopNoticeListRefresh = () => {
  const queryClient = useQueryClient();

  const refresh = () => {
    queryClient.refetchQueries(TOP_NOTICE_LIST_QUERY_KEY);
  };

  return { refresh };
};

export const getTopNoticeList = async () => {
  const { data } = await client(true).get<GetTopNoticeListResponse>('/portals/notices/top');

  return data;
};

export const useGetTopNoticeList = () => {
  const { data } = useQuery({
    queryKey: TOP_NOTICE_LIST_QUERY_KEY,
    queryFn: getTopNoticeList,
    keepPreviousData: true,
  });

  return { noticeTopList: data?.noticeResponses ?? [] };
};
