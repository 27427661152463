import { Flex } from '@teampetfriends/react-ui';

import type { SettlementItemProps } from '@dashboard/modules/interfaces';

import * as WhiteItem from '../../../WhiteItem';

type RequestItemProps = SettlementItemProps & { onClick?: () => void };

function RequestItem({ title, value, unit, onClick }: RequestItemProps) {
  return (
    <WhiteItem.Wrapper
      css={{
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '2.5rem 2rem',
        flex: 1,
      }}
      onClick={onClick}
    >
      <Flex direction='column' css={{ rowGap: '1.4rem' }}>
        <WhiteItem.Head title={title} />
        <WhiteItem.Content value={value} unit={unit} />
      </Flex>
      <WhiteItem.Arrow />
    </WhiteItem.Wrapper>
  );
}

export default RequestItem;
