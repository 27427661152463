import { NumberAndUnitCell, NumberAndWonCell } from '@components/Cell';
import { columnHelper, getValue, getValueWithNullable } from '@components/Table';
import { type AdminSalesRankingItem } from '@dashboard/modules/interfaces';

const adminSalesRankingColumnHelper = columnHelper<AdminSalesRankingItem>();

export const getAdminSalesRankingColumns = () => {
  return [
    adminSalesRankingColumnHelper.accessor('rank', {
      header: '순위',
      cell: getValue,
    }),
    adminSalesRankingColumnHelper.accessor('vendorName', {
      header: '거래처명',
      cell: getValueWithNullable,
    }),
    adminSalesRankingColumnHelper.accessor('orderCount', {
      header: '주문수',
      cell: (data) => <NumberAndUnitCell {...data} type='건' />,
    }),
    adminSalesRankingColumnHelper.accessor('paymentPrice', {
      header: '결제가',
      cell: (data) => <NumberAndWonCell {...data} />,
    }),
    adminSalesRankingColumnHelper.accessor('supplyPrice', {
      header: '공급가',
      cell: (data) => <NumberAndWonCell {...data} />,
    }),
    adminSalesRankingColumnHelper.accessor('marginPrice', {
      header: '마진',
      cell: (data) => <NumberAndWonCell {...data} />,
    }),
  ];
};
