import { useId } from 'react';

import { flexRender } from '@tanstack/react-table';

import type { TableProps } from './type';

import './table.css';

function Table<T>({
  variants,
  tableLayout,
  onClick,
  wrapperClassName = '',
  emptyNode,
  ...config
}: TableProps<T>) {
  const uniqueKey = useId();

  return (
    <div className={`w-full overflow-auto tableWrapper max-h-[60rem] ${wrapperClassName}`}>
      <table className={`main-table ${variants} ${tableLayout ?? 'table-auto'}`}>
        <thead>
          {config.getHeaderGroups().map((hGroup) => (
            <tr key={`thead-${hGroup.id}-${uniqueKey}`} role='row'>
              {hGroup.headers.map((header) => (
                <th
                  key={header.id}
                  {...{
                    colSpan: header.colSpan,
                    style: {
                      width: header.getSize(),
                    },
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {config.getRowModel().rows.length > 0
            ? config.getRowModel().rows.map((row) => (
                <tr
                  key={`tbody-${row.id}-${uniqueKey}`}
                  {...(onClick && {
                    className: 'row-link',
                    onClick: () => onClick?.(row),
                  })}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            : emptyNode && (
                <tr>
                  <td colSpan={config.getHeaderGroups()[0].headers.length}>{emptyNode}</td>
                </tr>
              )}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
