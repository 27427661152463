import { getCalcDate } from '@utils/util';

import type { CouponReportParams } from './interfaces';

export const COUPON_REPORT_INITIAL_PARAMS: CouponReportParams = {
  dateCondition: 'COUPON_CREATED_DATE',
  startDate: getCalcDate(new Date(), 1, 'month'),
  endDate: new Date(),
  searchCondition: 'COUPON_NAME',
  searchContents: '',
};

export const ONlY_NUMBER_STATUS = ['COUPON_CODE', 'PRODUCT_ID', 'VENDOR_ID'];
