import { Flex } from '@teampetfriends/react-ui';

import { CLAIM_INQUIRE_INITIAL_STATE } from '@claim/ClaimInquire/module/constants';
import type { ClaimsParams } from '@claim/ClaimInquire/module/interfaces';
import { useRoute } from '@hooks/useRoute';
import type { Entries } from '@utils/type';
import { getCalcDate } from '@utils/util';

import { useGetClaimSummaryWithDashboard } from '../apis';
import { useAuthCheck } from '../hooks/useAuthCheck';

import { Board, BoardTitle } from './Board';
import InlineItem from './InlineItem';

function ClaimSection() {
  const { push } = useRoute();
  const { data } = useGetClaimSummaryWithDashboard();
  const {
    exchangeRequestedCount,
    returnRequestedCount,
    delayedCollectionInvoiceCount,
    delayedCompletedCount,
  } = data;

  const checkAuth = useAuthCheck();

  const onClickNavigateClaimPage = (params: Partial<ClaimsParams>) =>
    checkAuth(['VENDOR', 'CONSIGNMENT'], () => {
      let defaultParams: ClaimsParams = {
        ...CLAIM_INQUIRE_INITIAL_STATE,
        productStockType: 'VENDOR',
      };

      (Object.entries(params) as Entries<ClaimsParams>).forEach(([key, value]) => {
        defaultParams = { ...defaultParams, [key]: value };
      });

      push('/exchange-return', defaultParams);
    });

  return (
    <Board css={{ background: '#F9F9FA', flex: 1, height: '100%' }}>
      <BoardTitle title='클레임' description='최근 1개월 기준' css={{ pb: '2rem' }} />
      <Flex as='ul' direction='column' css={{ mt: '3rem', rowGap: '1rem' }}>
        <InlineItem
          label='교환접수'
          value={exchangeRequestedCount}
          unit='건'
          onClick={() =>
            onClickNavigateClaimPage({
              claimType: 'EXCHANGE',
              claimStatus: 'REQUESTED',
            })
          }
        />
        <InlineItem
          label='반품접수'
          value={returnRequestedCount}
          unit='건'
          onClick={() =>
            onClickNavigateClaimPage({
              claimType: 'RETURN',
              claimStatus: 'REQUESTED',
            })
          }
        />
        <InlineItem
          label='수거송장 등록지연'
          value={delayedCollectionInvoiceCount}
          unit='건'
          onClick={() =>
            onClickNavigateClaimPage({
              endDate: getCalcDate(new Date(), 3, 'day'),
              claimStatus: 'APPROVED',
            })
          }
        />
        <InlineItem
          label='완료처리 지연'
          value={delayedCompletedCount}
          unit='건'
          onClick={() =>
            onClickNavigateClaimPage({
              endDate: getCalcDate(new Date(), 2, 'day'),
              claimStatus: 'COLLECTION_COMPLETED',
            })
          }
        />
      </Flex>
    </Board>
  );
}

export default ClaimSection;
