import { useMemo, useState } from 'react';

import { Flex } from '@teampetfriends/react-ui';

import { getTokenData } from '@utils/token';

import { Board, BoardTitle } from '../Board';

import AdminRankingTable from './modules/components/AdminRankingTable';
import { SalesRankingProvider } from './modules/components/context/createSalesRankingContext';
import SortSelect from './modules/components/SortSelect';
import VendorRankingTable from './modules/components/VendorRankingTable';

function SalesRankingSection() {
  const checkUser = getTokenData();

  const [sortCondition, setSortCondition] = useState('ORDER_COUNT');

  const value = useMemo(() => {
    return {
      sortCondition,
      onChangeSortCondition: (value: string) => setSortCondition(value),
    };
  }, [sortCondition]);

  return (
    <Board css={{ background: '#F9F9FA' }}>
      <BoardTitle title='판매순위' description='최근 15일 기준' css={{ pb: '2rem' }} />
      <Flex
        direction='column'
        css={{
          background: 'var(--color-white)',
          borderRadius: '1rem',
          padding: '4rem',
          mt: '2rem',
          rowGap: '2rem',
        }}
      >
        <SalesRankingProvider value={value}>
          <SortSelect />
          {checkUser?.is_vendor ? <VendorRankingTable /> : <AdminRankingTable />}
        </SalesRankingProvider>
      </Flex>
    </Board>
  );
}

export default SalesRankingSection;
